import React, { useState, useEffect } from 'react';
import '../src/App.css';

//Map
import Carte from './Components/Carte';

//Fonctions
function debounce(fn, ms) {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

function App() {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    useEffect(() => {
        let vh = dimensions.height * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }, 1000);

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [dimensions]);

    return (
        <div className='App'>
            <Carte dimensions={dimensions} />
        </div>
    );
}

export default App;
