import React, { useEffect, useState, useRef } from 'react';

import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, Polyline, ZoomControl } from 'react-leaflet';

//Hooks
import { usePosition } from 'use-position';

//Icons
import UserLocIcon from './UserLocIcon';
import BusLocIcon from './BusLocIcon';
import userPosition from '../assets/user-position.svg';
import bus from '../assets/bus.svg';
import arret from '../assets/arret.svg';

//CONSTANTES
const { REACT_APP_TOKEN, REACT_APP_BUS_CODE, REACT_APP_LINE_CODE } = process.env;

const arretIcon = new L.Icon({
    iconUrl: arret,
    iconSize: [25, 25],
});
const busIcon = new L.Icon({
    iconUrl: bus,
    iconSize: [50, 50],
    iconAnchor: [25, 50],
});
const userIcon = new L.Icon({
    iconUrl: userPosition,
    iconSize: [40, 40],
});

const circuit = [
    [42.61900277453324, 9.435764852534131],
    [42.61899867538427, 9.435772794138574],
    [42.61900492057442, 9.435770978514766],
    [42.61902162974664, 9.435733960915559],
    [42.61900987694694, 9.43564342392807],
    [42.61900941171332, 9.435634838471548],
    [42.61898887571575, 9.435615130206273],
    [42.61895445740118, 9.435579902502401],
    [42.61892964435425, 9.43553168421575],
    [42.61891164043084, 9.435474220849613],
    [42.61890290736141, 9.435402207141188],
    [42.61889852129922, 9.435318379337893],
    [42.61890326889167, 9.435229327974827],
    [42.61891985342032, 9.435135467882642],
    [42.61894234817112, 9.435040968271458],
    [42.61897044408305, 9.434941780236894],
    [42.61899937570696, 9.43483775961025],
    [42.61902839211793, 9.434727323977253],
    [42.61905605094693, 9.434614059956969],
    [42.61908120081561, 9.434498040637644],
    [42.61909093087388, 9.434437521149336],
    [42.61911268590004, 9.434318333217215],
    [42.61913445931932, 9.43420252002635],
    [42.6191561796301, 9.434085973822341],
    [42.61917816256942, 9.433968577848256],
    [42.61921094680376, 9.433795001591514],
    [42.61922130111514, 9.433739885418174],
    [42.61924786750438, 9.433627701980205],
    [42.61928431867972, 9.433520368126402],
    [42.61933087574522, 9.433417493971989],
    [42.61938941020757, 9.433312129907542],
    [42.61945456058837, 9.43320617135493],
    [42.61951874186714, 9.433101683517002],
    [42.61957642374366, 9.43299519687829],
    [42.61962676744105, 9.43288357149954],
    [42.61967136816702, 9.432765488159957],
    [42.61970809874004, 9.432644892833585],
    [42.61973414961812, 9.432526884225277],
    [42.6197428750915, 9.432411122632681],
    [42.61972760150388, 9.432217840833749],
    [42.61975707063412, 9.431664452212324],
    [42.61975241258232, 9.431735892525666],
    [42.61984058232537, 9.431576601515639],
    [42.61987658948586, 9.431530482332811],
    [42.61991143655153, 9.431477775721531],
    [42.61997738455685, 9.431381087582279],
    [42.62003663324325, 9.43127730321784],
    [42.62009302469615, 9.431167934556369],
    [42.62014601594989, 9.43105184045513],
    [42.62019807331271, 9.430932162306384],
    [42.62024010600187, 9.430802902554174],
    [42.62027104923693, 9.43066723562697],
    [42.62029379517203, 9.430530347130979],
    [42.62031060162089, 9.430392349430431],
    [42.62032206620155, 9.430250594445567],
    [42.62033929290158, 9.430103394599637],
    [42.62036238995211, 9.429951493884845],
    [42.62038367375164, 9.429799360225374],
    [42.62040387959413, 9.429650822828295],
    [42.62042216125963, 9.429508841378075],
    [42.62043607867877, 9.429371063955838],
    [42.62044114835133, 9.429232471995393],
    [42.62043294293868, 9.429092115947707],
    [42.62040040918249, 9.428953861988836],
    [42.62034786670119, 9.42881917972524],
    [42.62028232231565, 9.428691629860099],
    [42.62021316596158, 9.42857122843375],
    [42.620147930309, 9.428451671327794],
    [42.62009432891271, 9.428324634291359],
    [42.62004930478513, 9.428190597955608],
    [42.62001243582602, 9.428050876837407],
    [42.61998688537706, 9.427901537951307],
    [42.61997353378671, 9.42775187643303],
    [42.61996307843997, 9.427602449314099],
    [42.61995476093726, 9.42745409723535],
    [42.61994789935862, 9.427308982913313],
    [42.6199380411072, 9.427168812950312],
    [42.61992213292304, 9.427024583100089],
    [42.61990700142174, 9.426883755204353],
    [42.61989075497777, 9.426744752789968],
    [42.61986927306768, 9.426607380867333],
    [42.61984102359253, 9.426466872614668],
    [42.61980427433448, 9.426329262820744],
    [42.61975868519067, 9.426193504891069],
    [42.61970423268454, 9.426064393257079],
    [42.61964070495877, 9.425940624375023],
    [42.61956888410664, 9.42581965804283],
    [42.61949307279034, 9.425705614620462],
    [42.61941616243798, 9.425598591071507],
    [42.6193410707174, 9.425496429520617],
    [42.61927338915395, 9.425400491848897],
    [42.61921249735904, 9.425313154147226],
    [42.61915900587955, 9.425232274262289],
    [42.61911363936336, 9.425160879378593],
    [42.61907725716335, 9.425100568093063],
    [42.61904698955524, 9.425052797545044],
    [42.61903773171181, 9.425029017233651],
    [42.61894872760302, 9.424854693789033],
    [42.61895421328915, 9.424860829007075],
    [42.61893842100015, 9.424835260193779],
    [42.61892127279555, 9.424806875383547],
    [42.61888409190613, 9.424749935198246],
    [42.61884664470165, 9.424693398782152],
    [42.61880702710285, 9.424635291833557],
    [42.61876513349994, 9.424575702007189],
    [42.61872216759517, 9.424516892098895],
    [42.61868116661647, 9.424459405991735],
    [42.6186408777932, 9.424403182183486],
    [42.6186009713378, 9.424345737466789],
    [42.61856173946789, 9.424289799278005],
    [42.61852649431248, 9.4242379905776],
    [42.61852835242349, 9.424243390264564],
    [42.61858641622459, 9.424337279983838],
    [42.61858649388052, 9.424339632168294],
    [42.61860900844157, 9.424374415119418],
    [42.6186320720175, 9.424409039158697],
    [42.61867714657989, 9.424474889989176],
    [42.61872262485785, 9.424542382897155],
    [42.6187695229882, 9.424610774032498],
    [42.61881828009054, 9.424680394478461],
    [42.6188667669788, 9.424751865333382],
    [42.61891516114029, 9.424824045566972],
    [42.61896490288172, 9.424897421502649],
    [42.61901269360405, 9.424967980815032],
    [42.61905635009538, 9.425035989846506],
    [42.61918360162232, 9.425249769142404],
    [42.61928029542951, 9.425413824861412],
    [42.61938128464782, 9.425562744463216],
    [42.61941308133682, 9.425600067258102],
    [42.61944427315456, 9.42563746764017],
    [42.61953663394353, 9.425750699006388],
    [42.6195942677799, 9.42584046086281],
    [42.61965179935166, 9.425943385219401],
    [42.61970811255726, 9.426062356733565],
    [42.61976192600219, 9.426189104259615],
    [42.61981024685773, 9.426316658147048],
    [42.61985182648785, 9.426443771972902],
    [42.61988505119363, 9.426569708997038],
    [42.61991023319843, 9.426699691520893],
    [42.61993137028031, 9.426834699734531],
    [42.61995002560971, 9.426974987570118],
    [42.61996564258019, 9.427114183095048],
    [42.6199789010841, 9.427251260181334],
    [42.61998666610213, 9.427384100563794],
    [42.61999012031355, 9.427511234190934],
    [42.61999130063253, 9.427641548094847],
    [42.61999609936122, 9.427712191034182],
    [42.62001867050129, 9.427867205184674],
    [42.62006638114388, 9.428107493092584],
    [42.62011457770417, 9.428273626330325],
    [42.62016613695031, 9.428438594863788],
    [42.62021137390097, 9.428584995739373],
    [42.62026093184436, 9.428725052582442],
    [42.62031353337596, 9.428857772965339],
    [42.62036440338282, 9.428985701413803],
    [42.62040338798203, 9.429115380300592],
    [42.62042594677762, 9.429254038908747],
    [42.62043563363751, 9.429396486991914],
    [42.62043343294916, 9.429543726547495],
    [42.62041775402916, 9.429695163575031],
    [42.62039687204443, 9.429843298242812],
    [42.62036943687443, 9.429991456118415],
    [42.62033907813007, 9.43013341751633],
    [42.62031179567847, 9.430271120021819],
    [42.6202861796332, 9.430408649055753],
    [42.62026176300755, 9.430547273367681],
    [42.62023284844421, 9.430686246034494],
    [42.62021317341268, 9.430753884980531],
    [42.62017647609338, 9.430888390205542],
    [42.62012943218097, 9.43101851307409],
    [42.62007285565685, 9.431139646568189],
    [42.62001310725137, 9.43125449703402],
    [42.61995021350236, 9.431362684439922],
    [42.61988209994204, 9.431463710287721],
    [42.61981460087971, 9.431560200817527],
    [42.61975035345357, 9.431655100793282],
    [42.61968889526892, 9.431747244036172],
    [42.61963608747477, 9.431837810515184],
    [42.61960569572822, 9.431928371583044],
    [42.61959663480974, 9.432025132520636],
    [42.61960585099029, 9.432125687151521],
    [42.61963104324914, 9.432233389901002],
    [42.61965764156158, 9.432344711502374],
    [42.61967221826425, 9.432468538196105],
    [42.61967388221965, 9.432594625333412],
    [42.61966011326923, 9.432724328286588],
    [42.61962873628988, 9.432853666736566],
    [42.61954894747265, 9.433079922390132],
    [42.61946710572015, 9.433226659811798],
    [42.61937822300876, 9.433371773293192],
    [42.61928940700967, 9.433525034331083],
    [42.61926361061852, 9.433588723236713],
    [42.6192152931797, 9.433701095592642],
    [42.61918116930131, 9.433815096978346],
    [42.61915367921876, 9.433922481008496],
    [42.6191312091621, 9.434016014724618],
    [42.61910724971862, 9.434097658185513],
    [42.61908198949644, 9.434158184916521],
    [42.61904929619747, 9.434197052276863],
    [42.61901639172153, 9.434211251888811],
    [42.61898079207431, 9.434209323964982],
    [42.61894233563278, 9.434196164686279],
    [42.61889531315318, 9.434181665337364],
    [42.61884555208302, 9.434167613782323],
    [42.61878813728876, 9.434157274983358],
    [42.61872365983143, 9.434148540634835],
    [42.61865559717062, 9.434139717427952],
    [42.61858752633863, 9.434131248111777],
    [42.61851781455163, 9.434123511650908],
    [42.61844418959863, 9.43411628259091],
    [42.61836684502836, 9.43410661939712],
    [42.61828556741379, 9.434088976149415],
    [42.61820240541699, 9.434067065727124],
    [42.61811771868496, 9.434037113367706],
    [42.61803525511997, 9.433995724095029],
    [42.61795578099041, 9.433944714005383],
    [42.61788036122094, 9.43388861368574],
    [42.61780880701733, 9.433825418510615],
    [42.61774045367041, 9.43376076418415],
    [42.61767515650968, 9.433698864700277],
    [42.61761215181419, 9.433640699754136],
    [42.61755075549195, 9.433586618880375],
    [42.61748935167923, 9.433534821564294],
    [42.61742689046741, 9.433482403158557],
    [42.61736072010996, 9.433428676429642],
    [42.61729249300948, 9.433372677802016],
    [42.61722241422242, 9.433311689608205],
    [42.61715143418937, 9.433243251171573],
    [42.61708363638515, 9.433163631227306],
    [42.6170206998031, 9.433073493524171],
    [42.61696358821752, 9.432973020437753],
    [42.61691258693136, 9.432866158404682],
    [42.61686513535425, 9.432756123046083],
    [42.61681224782708, 9.432645825799556],
    [42.61675057983778, 9.432535359293933],
    [42.61668344830707, 9.432434508493369],
    [42.61661134290435, 9.432341378836504],
    [42.61653649455914, 9.43225455045989],
    [42.61646678256677, 9.432174985625684],
    [42.61640307936628, 9.432102944103374],
    [42.616342258663, 9.432032956710481],
    [42.61628660196005, 9.431966686166191],
    [42.61623448799637, 9.431905193839244],
    [42.61618519393343, 9.431845905026565],
    [42.61614008707826, 9.431786941358023],
    [42.61609943834382, 9.431727629375548],
    [42.61606252896532, 9.431664642877783],
    [42.61603369006426, 9.431598255277049],
    [42.61600932005326, 9.431528527165481],
    [42.61598733016056, 9.431453947688958],
    [42.61596983182863, 9.431374036481845],
    [42.61595289933875, 9.431290612801808],
    [42.61593194163063, 9.431200818227889],
    [42.61590954384817, 9.431108533216852],
    [42.61588733940135, 9.431015334525517],
    [42.61584429974629, 9.430883821113106],
    [42.6158080742029, 9.43081886485967],
    [42.61576867776473, 9.430766932566826],
    [42.61573231554593, 9.430733177481196],
    [42.6157068531984, 9.430712027332245],
    [42.61568933259466, 9.4306914802421],
    [42.61558914237499, 9.430579253596495],
    [42.61558691949068, 9.430579629741212],
    [42.61556605769973, 9.430559986690469],
    [42.61552568955431, 9.430519025013737],
    [42.61548252497904, 9.430475612606385],
    [42.61543725677394, 9.430429861230888],
    [42.61538591287219, 9.430380157098856],
    [42.61532423042146, 9.43032725185339],
    [42.61526217339834, 9.430271536450984],
    [42.61520567243442, 9.430214044798454],
    [42.61515265892483, 9.430150469199047],
    [42.61511081066793, 9.43007742778342],
    [42.61507553012935, 9.429998889806933],
    [42.61504260001842, 9.429914400521747],
    [42.61501565887623, 9.429828373652153],
    [42.61499053647382, 9.429750294664032],
    [42.61496625686215, 9.429679305518034],
    [42.61493977883839, 9.429612926002136],
    [42.61490642386552, 9.42954688200256],
    [42.61487002139502, 9.429482037382945],
    [42.6148361018461, 9.429420305336983],
    [42.61480762891063, 9.429362021207707],
    [42.61478654669602, 9.429307422985454],
    [42.61477244286433, 9.429259099016853],
    [42.61476013752659, 9.429218529514875],
    [42.61474596539627, 9.429186708520904],
    [42.61476420079006, 9.429225648737232],
    [42.61487363629829, 9.429416623789459],
    [42.61486629177503, 9.4294175219809],
    [42.61487790090137, 9.429446541167843],
    [42.61490726809072, 9.429508975989515],
    [42.61493159799978, 9.42957597206768],
    [42.61495767034751, 9.429652175137903],
    [42.61497522791235, 9.429696782413052],
    [42.61500827166449, 9.429781931292887],
    [42.61504202986075, 9.429869103848151],
    [42.61507847100484, 9.42995656477995],
    [42.61511823820675, 9.430038692370269],
    [42.61516309767604, 9.430118132370563],
    [42.6152134204359, 9.430192084936746],
    [42.61527080184146, 9.430264309527061],
    [42.61533378811811, 9.430335110333573],
    [42.61540079732224, 9.43040465971103],
    [42.61547136429543, 9.430471935838414],
    [42.61554386801458, 9.430539825085201],
    [42.61561324287548, 9.430608892908126],
    [42.6156843423954, 9.4306790709631],
    [42.61575903096935, 9.430751337845031],
    [42.61582614465229, 9.430830148943919],
    [42.61588325255972, 9.4309145122097],
    [42.61593253650149, 9.431002274530435],
    [42.61597063552533, 9.431093725567745],
    [42.61599543705452, 9.431187957178505],
    [42.616012755024, 9.431278353052637],
    [42.61602844236464, 9.431363192764831],
    [42.61604288420971, 9.431442460956113],
    [42.61605696753217, 9.431518802454052],
    [42.61607289374813, 9.431593236950672],
    [42.61609727280881, 9.431668336912004],
    [42.61612680402182, 9.431745362536088],
    [42.61616474776405, 9.431820541919272],
    [42.6162138175882, 9.431894948087333],
    [42.61627206826493, 9.431967061543231],
    [42.61633549919242, 9.43203931714311],
    [42.61640344538249, 9.432111123969856],
    [42.61647316317836, 9.432185550546921],
    [42.61654565590813, 9.432264608714075],
    [42.6166181155852, 9.432349125959041],
    [42.61668797197664, 9.432435415650259],
    [42.61675371440579, 9.43252365962465],
    [42.616814493368, 9.432614290999515],
    [42.61690881119429, 9.432778596745512],
    [42.61697266790846, 9.432906457188315],
    [42.61704627640127, 9.433040607018743],
    [42.6171282292314, 9.433165907635363],
    [42.61716862464478, 9.433209496198486],
    [42.61724260929455, 9.433293403648392],
    [42.61731755167143, 9.43336643598029],
    [42.61739043319498, 9.433432305481139],
    [42.6174609798018, 9.43349676308255],
    [42.61752812467697, 9.433557566926735],
    [42.61759370233069, 9.433617457417741],
    [42.61765784073121, 9.433677225610355],
    [42.61772262096296, 9.433738106155689],
    [42.61778947256127, 9.433800075898352],
    [42.61785852228803, 9.433860928823139],
    [42.61792936594713, 9.433920343561581],
    [42.61800338044893, 9.433975176376611],
    [42.61808096837997, 9.434024140223521],
    [42.61816039893326, 9.434065023041667],
    [42.61823883032106, 9.434097562495765],
    [42.61831217731481, 9.434120746056886],
    [42.61837673323198, 9.434135614535762],
    [42.61843192177128, 9.43414354945482],
    [42.6184770195861, 9.434150715779037],
    [42.61870861681343, 9.434185298076043],
    [42.61870968043485, 9.4341851282524],
    [42.61873265688254, 9.434187142079729],
    [42.61877846574971, 9.434192497285194],
    [42.61882636323477, 9.434198061664231],
    [42.6188751272561, 9.43420589732302],
    [42.6189232423611, 9.434215318164819],
    [42.61894418575756, 9.434220521995274],
    [42.61898982774091, 9.434233247172726],
    [42.61902823152757, 9.434249580016411],
    [42.61906011716597, 9.434270631359754],
    [42.61909673320013, 9.434313206421269],
    [42.61910494110739, 9.434354264313043],
    [42.6191031207677, 9.434406494869542],
    [42.61908972542534, 9.434475106972229],
    [42.61906917861293, 9.434552707728658],
    [42.61904570574092, 9.434636623649256],
    [42.6190250066521, 9.434729199362161],
    [42.61900688367014, 9.434826117768342],
    [42.61898797448189, 9.434925245226863],
    [42.61896676630727, 9.43502803652129],
    [42.61894548439444, 9.435129257354895],
    [42.61892410099403, 9.435223760711374],
    [42.61890768107103, 9.43531239261503],
    [42.61889939696336, 9.435394622621393],
    [42.61890106021777, 9.435466964902831],
    [42.61891409273815, 9.435538229092954],
    [42.61894283581012, 9.435605156473354],
    [42.61898604837761, 9.435663267200983],
    [42.61904036517578, 9.435714600825083],
    [42.61910434683074, 9.435755542380347],
    [42.61917031216768, 9.435800869993841],
    [42.61924401635346, 9.435853618203215],
    [42.61932255926163, 9.435915731781478],
    [42.61940500999982, 9.435980935053248],
    [42.61948966638037, 9.436046273717878],
    [42.61957407618457, 9.436110803598627],
    [42.61965622206895, 9.436170315045995],
    [42.61972934907703, 9.436227994124451],
    [42.61979840544767, 9.436280252921749],
    [42.61986300669422, 9.436329512262466],
    [42.61992207916338, 9.436372954254338],
    [42.61997776692012, 9.436413128309253],
    [42.62002867821987, 9.436447657862239],
    [42.62005728723674, 9.436418546454878],
    [42.62017687796432, 9.436246149733897],
    [42.62017805942298, 9.436242816071495],
    [42.62018647199842, 9.436211381720883],
    [42.6201915316661, 9.436179815833777],
    [42.6202024469008, 9.436116160723742],
    [42.62021401561672, 9.436049136844813],
    [42.62022531059534, 9.435980512127124],
    [42.62023352869687, 9.435908309609824],
    [42.62024523739694, 9.43583078660653],
    [42.62025803649424, 9.435751385398248],
    [42.62027091306726, 9.435668965800803],
    [42.62028641577638, 9.435589974496617],
    [42.62030538668488, 9.4355101503958],
    [42.62032186160742, 9.435429787408742],
    [42.62033630227543, 9.43534702972932],
    [42.62035097271032, 9.435260554792226],
    [42.62036282729464, 9.435170206909739],
    [42.62037069871702, 9.435076539112911],
    [42.62037933588645, 9.434983184791731],
    [42.62038916150268, 9.434887045474333],
    [42.62039620929351, 9.434796360935048],
    [42.62040176948389, 9.434698792742772],
    [42.62040676584786, 9.434598623149197],
    [42.62041144611366, 9.434497053832825],
    [42.62041396950409, 9.434396646258612],
    [42.62041859953137, 9.434294626281121],
    [42.62042715300464, 9.434196059969835],
    [42.62043886917898, 9.434101604956707],
    [42.62045338565954, 9.434011343084757],
    [42.62047181550322, 9.433923266467067],
    [42.62049329242974, 9.433835427535696],
    [42.62051331810368, 9.433747602556945],
    [42.62053428498209, 9.433660234001048],
    [42.62055037557383, 9.433574622966139],
    [42.62056324551903, 9.433491582055],
    [42.62057271429903, 9.433407088919989],
    [42.62058072943514, 9.433320546004785],
    [42.62058290574055, 9.43322870150994],
    [42.62058802432142, 9.433136212890176],
    [42.62059959407019, 9.433046709245216],
    [42.62061376925853, 9.432954376786716],
    [42.62063323273359, 9.432862894214619],
    [42.6206603337392, 9.432780740920043],
    [42.62068923066388, 9.432707948750672],
    [42.62071306120792, 9.432646362139298],
    [42.62073358817718, 9.432605530997414],
    [42.62074214715267, 9.43258229050093],
    [42.62079961112472, 9.43241295155457],
    [42.62080119873428, 9.432412714894388],
    [42.62081222192918, 9.432383437453543],
    [42.62083591860016, 9.432325058283656],
    [42.62086226885577, 9.432263569136737],
    [42.62089014436697, 9.43220535936034],
    [42.6209160350388, 9.432142698403446],
    [42.62092614750876, 9.432106961892829],
    [42.6209479316607, 9.432040049828093],
    [42.62096873415984, 9.431972474171427],
    [42.62099153112642, 9.431901973049865],
    [42.62102721792747, 9.431796654741659],
    [42.62106282019029, 9.431720201893633],
    [42.62110838292944, 9.43164033992473],
    [42.62115799056757, 9.431555474405691],
    [42.62121314139789, 9.431470892614506],
    [42.62126290783172, 9.431385598816636],
    [42.62130922411331, 9.431299941502022],
    [42.62135284052576, 9.431212708328706],
    [42.62139359188372, 9.431123577406696],
    [42.62143386447399, 9.431040477845741],
    [42.62147964864258, 9.430957489674471],
    [42.62152442830485, 9.430878588216425],
    [42.62156294143714, 9.430792973654766],
    [42.62159505012882, 9.430696501788546],
    [42.62161677512576, 9.430593943750495],
    [42.62163503925523, 9.430487692053319],
    [42.62165750137559, 9.430384475487022],
    [42.62168216667497, 9.4302853763666],
    [42.62170851170218, 9.430183666557769],
    [42.62173423812226, 9.43007833045421],
    [42.62176251944546, 9.429970563327766],
    [42.62179105216114, 9.429857930551027],
    [42.62181817230509, 9.42974636096362],
    [42.62184468081244, 9.429637186443472],
    [42.62186877975904, 9.429531246064922],
    [42.62189418145446, 9.429429039562002],
    [42.62191982922625, 9.429331712055033],
    [42.62194493150002, 9.429234429073343],
    [42.62196940291733, 9.42913304823467],
    [42.62199137679683, 9.429024259198934],
    [42.62200921229267, 9.428910403399092],
    [42.62201044500928, 9.428760465317497],
    [42.62202722486386, 9.427824413781687],
    [42.62202579493018, 9.427809887101736],
    [42.62202876874902, 9.427685918628097],
    [42.62202519559727, 9.427559008089155],
    [42.62202323792574, 9.427517842837306],
    [42.62202032588034, 9.427429159549044],
    [42.6220220747337, 9.42734740061721],
    [42.62202774910047, 9.427279418382751],
    [42.62203518559912, 9.427209281691729],
    [42.62204479838749, 9.427132167422412],
    [42.62205158924493, 9.427043548016725],
    [42.62205999508421, 9.426950654996297],
    [42.62206561730977, 9.426860538088579],
    [42.62206851354373, 9.426783541390499],
    [42.62207767150507, 9.426703279562556],
    [42.62211220294656, 9.426335856852127],
    [42.62211131560313, 9.426330558939114],
    [42.62211127273898, 9.42627961228826],
    [42.62211186234956, 9.426226400013494],
    [42.6221143545062, 9.42607992063297],
    [42.62211781393908, 9.426025573873407],
    [42.62212589445808, 9.425867626829458],
    [42.62212940658522, 9.425813237008072],
    [42.62214169706092, 9.425647603137577],
    [42.62214996761851, 9.425533560704233],
    [42.622154101253, 9.425481717082723],
    [42.62216679024679, 9.425304519304071],
    [42.62217524703433, 9.425188979227407],
    [42.62217726032007, 9.425135670775289],
    [42.62217922497876, 9.424963943335925],
    [42.62217474177532, 9.424842836537996],
    [42.62216992909186, 9.424777925708666],
    [42.62216399788885, 9.424664013875496],
    [42.62216066677433, 9.424557054818964],
    [42.62215838787152, 9.424448080741902],
    [42.62215527017058, 9.424339902630653],
    [42.6221508514714, 9.424232984930118],
    [42.62214401020083, 9.424124635451024],
    [42.62213181898897, 9.424013223427917],
    [42.62211588309134, 9.423900359662303],
    [42.62209666777311, 9.423784967955845],
    [42.62207597962414, 9.423668397037597],
    [42.62205099383677, 9.423552489996032],
    [42.62201349345211, 9.423379589530352],
    [42.62198980153648, 9.423263406390845],
    [42.62197793185283, 9.423205627437749],
    [42.62194307938397, 9.423032863939753],
    [42.62193280333907, 9.422973344598132],
    [42.62191212274341, 9.422856373002924],
    [42.62189375435952, 9.422737605591879],
    [42.62188177136763, 9.422616428285977],
    [42.6218795878402, 9.422497789452644],
    [42.62189308228278, 9.422319484568217],
    [42.6219141355563, 9.422275864308428],
    [42.62195755543562, 9.422178388240685],
    [42.62201833070415, 9.422098476132172],
    [42.62208955537501, 9.422037421917974],
    [42.6221652087011, 9.421991169399679],
    [42.62226145195626, 9.421939946856883],
    [42.62228295421121, 9.421908629951499],
    [42.6223236713133, 9.42184695306886],
    [42.62235090442854, 9.42176988621301],
    [42.62236740026015, 9.42167137096045],
    [42.62237857820461, 9.421560500210999],
    [42.62238731053141, 9.421445898513022],
    [42.62239394296074, 9.421334922563501],
    [42.62239902200415, 9.421220295594448],
    [42.62240166777553, 9.421102398389262],
    [42.6224021735527, 9.420982291317033],
    [42.62239664752083, 9.420863724730699],
    [42.62242480354298, 9.420790710574025],
    [42.62272323816452, 9.420194773147456],
    [42.62271718224127, 9.420190258949095],
    [42.62274299159358, 9.420178316536134],
    [42.62276683089384, 9.420174901284581],
    [42.62284843872902, 9.420168423149001],
    [42.62289138121514, 9.420192353119136],
    [42.62291350581526, 9.420218461560326],
    [42.62296270727737, 9.420271374642804],
    [42.62303456932026, 9.420355624011549],
    [42.62308859458295, 9.420411488377827],
    [42.62314934573805, 9.420451629412733],
    [42.62321313704227, 9.420475427231954],
    [42.6232778677972, 9.420483029720069],
    [42.6233400735348, 9.42047987439123],
    [42.62339717753213, 9.420475253578163],
    [42.62345469542122, 9.420467213270758],
    [42.62351128787367, 9.420450985697528],
    [42.62356489406815, 9.42042165019633],
    [42.62361412376423, 9.420378149983677],
    [42.62365859262943, 9.420325811835205],
    [42.62370125231829, 9.420270304930813],
    [42.62374850781551, 9.420217373882549],
    [42.62380409160931, 9.420174635804777],
    [42.62386741642231, 9.420146914264297],
    [42.62400545709195, 9.420143960044761],
    [42.62412080773087, 9.42016267879],
    [42.62424312502567, 9.420190462168447],
    [42.62428551958004, 9.420200432450217],
    [42.6243720785812, 9.420217910478872],
    [42.62445606551305, 9.420233090836431],
    [42.62453566036243, 9.420239559259308],
    [42.62461220588985, 9.420235735785958],
    [42.62468846754675, 9.420229806984958],
    [42.62475997763067, 9.420223545661226],
    [42.62482911274066, 9.420222704399343],
    [42.62489442613315, 9.420239259129561],
    [42.62495317256701, 9.420272655332147],
    [42.6250074354371, 9.420331272902416],
    [42.62505761294914, 9.420413219305852],
    [42.62510253277054, 9.420504052995154],
    [42.62514924373856, 9.420594143586623],
    [42.62519324422316, 9.420675406278015],
    [42.62524012554338, 9.420754816346417],
    [42.62528998640103, 9.420826677858429],
    [42.62533474476365, 9.420885458164985],
    [42.62536079784899, 9.4208671139906],
    [42.625461615817, 9.420729950238581],
    [42.62546074013802, 9.420733429646283],
    [42.62546417827004, 9.420699303722476],
    [42.62546938483539, 9.42062897474114],
    [42.62548033830425, 9.420558678130392],
    [42.62550024978671, 9.420492633846479],
    [42.62553206006491, 9.420440962189833],
    [42.62557822011987, 9.420411861186894],
    [42.62562560616863, 9.420418921113612],
    [42.62566727311749, 9.420460276255064],
    [42.62569711034856, 9.420530413095181],
    [42.62571065515039, 9.420622626263944],
    [42.62571633956049, 9.4207232506248],
    [42.62571837486256, 9.420820634620947],
    [42.62572359755696, 9.420914521921349],
    [42.62574140178562, 9.420997836856094],
    [42.62576963962243, 9.421064332957865],
    [42.62580998558844, 9.421113661676682],
    [42.62586207925592, 9.421145191600033],
    [42.62592121899946, 9.421170595768984],
    [42.62598650069268, 9.421187187891528],
    [42.62605433909669, 9.421190552941688],
    [42.62611770977809, 9.42117740897568],
    [42.62617979947326, 9.421140973050415],
    [42.62624024055039, 9.421089073898184],
    [42.62630214531353, 9.421028721586433],
    [42.62636743297045, 9.420965452897459],
    [42.62643281742527, 9.420902535187032],
    [42.62649556112141, 9.420842983677122],
    [42.62655289089398, 9.420790604950687],
    [42.6266073160736, 9.420743721136118],
    [42.6266594170869, 9.420704907702905],
    [42.62670911304001, 9.420674143609826],
    [42.62675637595335, 9.420650106466853],
    [42.62680426955065, 9.420665154281904],
    [42.6270170750102, 9.420750041938916],
    [42.6270182997217, 9.420750497474609],
    [42.62703688055768, 9.420766205375626],
    [42.62705523605595, 9.420780866366782],
    [42.62709314579136, 9.420809521006939],
    [42.62713324230334, 9.420835825403568],
    [42.62717458248626, 9.420859508838701],
    [42.62721657668264, 9.420879761707646],
    [42.62725989260863, 9.42089837315908],
    [42.62730175653193, 9.420915744733572],
    [42.6274944701658, 9.421103159935587],
    [42.62752810556224, 9.421143531334398],
    [42.6275246382569, 9.421145603763174],
    [42.62753788445049, 9.421168627823871],
    [42.62754930603271, 9.421190505562473],
    [42.62757140745407, 9.421235400491787],
    [42.62759682903622, 9.421304697662649],
    [42.62760675186777, 9.421355033271348],
    [42.62761502848119, 9.421409863170839],
    [42.62762458561286, 9.421465568576551],
    [42.62763569314375, 9.421521448605702],
    [42.62765110926863, 9.421583213426338],
    [42.6277112384594, 9.421934974797907],
    [42.62771291270668, 9.421937665482403],
    [42.62770876836854, 9.421973054856773],
    [42.6276992285682, 9.422041448320966],
    [42.62768955698913, 9.422105979658157],
    [42.62768001171114, 9.422167954505079],
    [42.62767175117256, 9.422229570967769],
    [42.62766693832898, 9.422294774800115],
    [42.62766751338398, 9.422361381160473],
    [42.62766884653268, 9.422426814971434],
    [42.6276726616083, 9.422489529292509],
    [42.62767500409506, 9.422550991560163],
    [42.62767444789566, 9.422607361226882],
    [42.62768807479676, 9.422702249590793],
    [42.62779395340878, 9.423014934602953],
    [42.62778853125452, 9.42302292674286],
    [42.62781132561857, 9.423048721728611],
    [42.62785447929263, 9.423099918380847],
    [42.62788479626732, 9.423170889524192],
    [42.627889963363, 9.423261791944567],
    [42.62787290591985, 9.423356746999783],
    [42.62783564610689, 9.423442620793342],
    [42.62777637987156, 9.423504398198883],
    [42.62771512809792, 9.423541521382756],
    [42.62764740906437, 9.423554254359683],
    [42.62757883987542, 9.423544649991038],
    [42.62752076982893, 9.423520752621078],
    [42.62746781683966, 9.423495849497577],
    [42.62742019157535, 9.423488272269184],
    [42.62737548197845, 9.42351188912509],
    [42.6273350904251, 9.423569398603954],
    [42.62730605562418, 9.423643795093298],
    [42.62729820074956, 9.423723199643756],
    [42.62731422238257, 9.423804782194352],
    [42.62735367623487, 9.423883222569934],
    [42.62740786713311, 9.423956166785043],
    [42.62746608497095, 9.424024475634257],
    [42.62752891717559, 9.42407165639499],
    [42.62759011072924, 9.424100212767867],
    [42.62764767718217, 9.424113085792968],
    [42.62770983400103, 9.424105003240463],
    [42.62777015880024, 9.424082951539885],
    [42.62783223681768, 9.424040300799811],
    [42.62789997923166, 9.423978533926878],
    [42.62796691249132, 9.423908462106418],
    [42.62803682172381, 9.423836019818967],
    [42.62810438094934, 9.423763412727801],
    [42.62817203739307, 9.42369540941635],
    [42.62823974841078, 9.423635731371913],
    [42.62830553625501, 9.423587802625208],
    [42.62837426960566, 9.423551427775541],
    [42.62844118649899, 9.423519950341172],
    [42.62850746399022, 9.423480640567245],
    [42.62857318770829, 9.423421139883427],
    [42.62863036483657, 9.423344452855893],
    [42.62868551095034, 9.42325871864951],
    [42.62873560407989, 9.423176385814205],
    [42.62878105483031, 9.423108316226157],
    [42.62881135694949, 9.423129578735786],
    [42.62899879465897, 9.423345147448268],
    [42.6289939789685, 9.423350354345791],
    [42.62898288028647, 9.423398795973036],
    [42.62896471480992, 9.423488848880211],
    [42.62894662162609, 9.423581115404566],
    [42.62892845749686, 9.4236813341986],
    [42.62890969968773, 9.423784251094725],
    [42.62888940001913, 9.423889082335956],
    [42.6288649207355, 9.423997055214802],
    [42.6288390954421, 9.424105004111055],
    [42.62881455489019, 9.42421434668872],
    [42.62879110257278, 9.424322575334898],
    [42.62877267233893, 9.42443091986642],
    [42.62875659359764, 9.42453923706015],
    [42.62874507770426, 9.424644802008988],
    [42.62874002912488, 9.424751504653832],
    [42.62873834004994, 9.424863596695605],
    [42.62874136545925, 9.424984983689644],
    [42.62874508886954, 9.425117132355712],
    [42.62874667867058, 9.425255792856046],
    [42.62874601126325, 9.425393330287548],
    [42.62874166784912, 9.425534747449776],
    [42.62873216917395, 9.425672239226632],
    [42.62871740828677, 9.425804436768221],
    [42.62869694131668, 9.425929100739317],
    [42.62867323095738, 9.426048816943332],
    [42.6286465991823, 9.42616814716841],
    [42.62861854691476, 9.426287218797508],
    [42.62858994025388, 9.426404856995248],
    [42.62853817139363, 9.426615795700069],
    [42.62849884641677, 9.42677538230325],
    [42.62846506777296, 9.426936850135039],
    [42.62845768941843, 9.426995104471569],
    [42.62844287966127, 9.427113741442257],
    [42.62843897651834, 9.427174547710539],
    [42.62843050116375, 9.427292001098685],
    [42.62842407125729, 9.427473005907936],
    [42.62842483650703, 9.427592266980261],
    [42.62843199147612, 9.427696886717609],
    [42.62844250648146, 9.427798010036947],
    [42.62844987139442, 9.427884150597558],
    [42.62844528384345, 9.427960852061769],
    [42.62842817892761, 9.428026023221109],
    [42.62839415922191, 9.428068782191211],
    [42.6283452969626, 9.428095614146553],
    [42.62828850815799, 9.428102144025091],
    [42.62822380912746, 9.428097432430404],
    [42.62815513872246, 9.428094972883398],
    [42.62808578262507, 9.428092461803617],
    [42.62801483474112, 9.428094991361897],
    [42.62794153653542, 9.428101081620252],
    [42.62786921123498, 9.428109324772487],
    [42.62779635565669, 9.4281220348403],
    [42.62772632393602, 9.428140712297397],
    [42.62766152863903, 9.428169650986757],
    [42.62760503485792, 9.42820790280496],
    [42.62756099394, 9.428255828684989],
    [42.62752844110076, 9.428311087117891],
    [42.62750946856072, 9.428367856879509],
    [42.62750191790131, 9.42842602893356],
    [42.6275066006821, 9.428482753034311],
    [42.62752271465567, 9.428542015139612],
    [42.62754452128809, 9.42860620322875],
    [42.6275726153935, 9.428674474101387],
    [42.62760118096189, 9.428749316981854],
    [42.62762837962214, 9.428830597931981],
    [42.62765762205073, 9.428911139146109],
    [42.62768581490052, 9.428992561523145],
    [42.62771220481032, 9.429075186735256],
    [42.62773691314129, 9.429157989478647],
    [42.6277589032464, 9.42924414920507],
    [42.62777831978647, 9.429332151851382],
    [42.62779531928425, 9.429422152944918],
    [42.62780918780682, 9.429510110230536],
    [42.62782115082403, 9.429596284362272],
    [42.62783003148368, 9.429684355518143],
    [42.62783298616704, 9.429770484854435],
    [42.6278306289302, 9.429860751341353],
    [42.62782311626553, 9.429954045872721],
    [42.62781164635495, 9.430045920159639],
    [42.62780125256947, 9.430137356857173],
    [42.62775611646852, 9.430512824982157],
    [42.62773105812762, 9.430608548705679],
    [42.62766829764642, 9.431004232076306],
    [42.62766741267578, 9.431000927436934],
    [42.62766488582783, 9.431034524320516],
    [42.6276594422559, 9.431099645554552],
    [42.62765091735556, 9.431165631740708],
    [42.62764605804463, 9.431198911627373],
    [42.62763046294491, 9.431304046946721],
    [42.62762464042298, 9.431343875011045],
    [42.62760795020422, 9.431460045947153],
    [42.62759577225362, 9.431542925695997],
    [42.62758388521258, 9.431625621390374],
    [42.62757270765862, 9.43170436136173],
    [42.62756067716006, 9.431781364024197],
    [42.62754835035604, 9.431849428362694],
    [42.62753735707476, 9.431910747086706],
    [42.62758393353526, 9.431898726250511],
    [42.62783228716288, 9.431838653505165],
    [42.62783195042248, 9.43183947463184],
    [42.62786127097835, 9.431836944619599],
    [42.62792071495803, 9.431832845099933],
    [42.62802811951176, 9.431839503082553],
    [42.62810996730281, 9.431864354560076],
    [42.62820175680173, 9.431906836874274],
    [42.62825436575217, 9.431940918645036],
    [42.62833126787532, 9.431997919590366],
    [42.62840055083813, 9.432054436176738],
    [42.62842614408517, 9.432082697449081],
    [42.62847725406425, 9.432135993477216],
    [42.62852601918444, 9.432190313169134],
    [42.6287555117659, 9.432472680636003],
    [42.6287547458331, 9.43247542199386],
    [42.62877092961793, 9.432509826442953],
    [42.62880195814661, 9.432583127019658],
    [42.62883122119477, 9.432666842531351],
    [42.62885997652955, 9.432758839051273],
    [42.62888526794829, 9.432858723177546],
    [42.62891355044898, 9.432961219253217],
    [42.62894784785122, 9.433068681501315],
    [42.62898847924758, 9.433181122616345],
    [42.62903273612891, 9.433299579079444],
    [42.62907822975264, 9.433424123035836],
    [42.62912058957949, 9.433538109895416],
    [42.62916508777838, 9.433667662240364],
    [42.62920845618588, 9.433790911887128],
    [42.62924670048059, 9.433903564485853],
    [42.62927390384363, 9.433991199196637],
    [42.62930037275854, 9.434077874855705],
    [42.62932246072267, 9.434150919565592],
    [42.62934326734658, 9.434216655022736],
    [42.62936383724301, 9.434275007869699],
    [42.62938356141161, 9.43433210105445],
    [42.62940824355616, 9.434398256674008],
    [42.62943877073491, 9.434481186933951],
    [42.62947188198726, 9.434568193809227],
    [42.62951256913981, 9.434663231418863],
    [42.62955431264934, 9.434762867802505],
    [42.62958488669496, 9.434874660532016],
    [42.62961695705884, 9.434996777440578],
    [42.6296482228651, 9.435121248041117],
    [42.62967838078951, 9.435244596660308],
    [42.62971916465089, 9.435359252422021],
    [42.62975662833731, 9.435469408867991],
    [42.62978980420577, 9.435575638027739],
    [42.62981979317548, 9.435676927606474],
    [42.62984845542181, 9.435776277958778],
    [42.62987706051619, 9.435875754445513],
    [42.62990740262487, 9.435980438222689],
    [42.63001122128154, 9.436368116026655],
    [42.63002887792933, 9.436467246371269],
    [42.6300216504574, 9.436461569559121],
    [42.6300169868552, 9.436499124114583],
    [42.6300062646172, 9.436571022350689],
    [42.62997872298241, 9.436625941465758],
    [42.62993708261593, 9.436664654030565],
    [42.62985640074631, 9.436657266058274],
    [42.62923067813951, 9.436490995170768],
    [42.62921213413284, 9.436490432035196],
    [42.62916806199662, 9.436451887857773],
    [42.62912414566082, 9.436423656268044],
    [42.62905513484567, 9.43638019289245],
    [42.62894819363427, 9.436347473390228],
    [42.62888298706559, 9.436362002847883],
    [42.62883204171445, 9.436404736729457],
    [42.62879826398186, 9.436469119745718],
    [42.62878000319616, 9.43654890104712],
    [42.62876980170378, 9.436638171447184],
    [42.62874530235362, 9.436737701888267],
    [42.62872291423815, 9.436834362348193],
    [42.62871456059489, 9.436879059746317],
    [42.6286986934421, 9.436962250565049],
    [42.62868888512023, 9.437025801297819],
    [42.62861252550368, 9.437364896075557],
    [42.62861314931665, 9.437365772704823],
    [42.6285994803597, 9.437397442519117],
    [42.62857584706178, 9.437462468081598],
    [42.62856302264066, 9.437533499174378],
    [42.62856370388228, 9.437607776442468],
    [42.62857923531856, 9.437677890160421],
    [42.62860736876065, 9.437740285820579],
    [42.62864403439453, 9.437795441949],
    [42.62868579317739, 9.437844891816926],
    [42.62872664761466, 9.437899326546821],
    [42.62876209612806, 9.437962095569937],
    [42.62878515570156, 9.438034872405478],
    [42.62879396350345, 9.438117656237537],
    [42.62878804024812, 9.43820375400105],
    [42.62876636686872, 9.438289547196467],
    [42.62873803577202, 9.438375678589001],
    [42.62871074519184, 9.438463241320459],
    [42.62869361679648, 9.438551210036875],
    [42.62868906837645, 9.438649245709199],
    [42.6286950671899, 9.438752022902554],
    [42.62870786056463, 9.438864769246489],
    [42.62872175495473, 9.438981944161812],
    [42.62873558234163, 9.439097986160789],
    [42.62874835174862, 9.439213728723049],
    [42.62875979287853, 9.439330896362412],
    [42.62876987865343, 9.439447807534545],
    [42.62877822143736, 9.439566234261994],
    [42.62878544157586, 9.439684392055545],
    [42.62879198151099, 9.439808314920128],
    [42.62879783300458, 9.439932455607321],
    [42.6288036418815, 9.440057327802462],
    [42.62880980455187, 9.440188582310579],
    [42.62881617661278, 9.440317156857905],
    [42.62882208863765, 9.440433009678252],
    [42.6288266377983, 9.440533752773414],
    [42.62882872341856, 9.44061735738277],
    [42.62879166256106, 9.440672607902098],
    [42.62854958120381, 9.440896026391174],
    [42.62854975008868, 9.440899613538141],
    [42.62850498993516, 9.440903802784208],
    [42.62842168729307, 9.440904942914813],
    [42.62833726799411, 9.440908752899812],
    [42.6282465999196, 9.440911761169385],
    [42.62815178111091, 9.440913902041522],
    [42.62804853914491, 9.440921421323342],
    [42.62793725717636, 9.440928750185549],
    [42.62782129807272, 9.440937200538603],
    [42.62769934594903, 9.440946957303533],
    [42.62757052110432, 9.440957601605602],
    [42.62744128346271, 9.440968214446137],
    [42.62731107351109, 9.440978618090076],
    [42.62718419363749, 9.440988402960198],
    [42.62706430174214, 9.440997987074841],
    [42.62695003139164, 9.441008636212441],
    [42.6268411753239, 9.441019103159741],
    [42.62673888984076, 9.441030782671433],
    [42.62664168919649, 9.441049736945635],
    [42.62654913114071, 9.441078719487553],
    [42.626460488114, 9.441118985717637],
    [42.62637373553872, 9.441166816052345],
    [42.62628394820006, 9.441217308451892],
    [42.62618816204946, 9.441267521836203],
    [42.62608140508049, 9.441321769874975],
    [42.62597314402358, 9.441375058050207],
    [42.62586841890866, 9.441423458093116],
    [42.62577039064781, 9.441463627132865],
    [42.62566584418708, 9.441497560676479],
    [42.62555409927069, 9.441526428501643],
    [42.62543739265737, 9.44155068209143],
    [42.62531744747381, 9.441572709421621],
    [42.62520286415537, 9.441592821419508],
    [42.62509393426419, 9.441615370035272],
    [42.62498975083984, 9.441644651096148],
    [42.62488985538415, 9.441686537769437],
    [42.62479691663069, 9.441742057745223],
    [42.62470736389388, 9.441811452032136],
    [42.62426754194014, 9.442280880996114],
    [42.62419973963042, 9.442412450921751],
    [42.62420890426726, 9.442402054399263],
    [42.62418297993599, 9.442461928232415],
    [42.62415440434415, 9.442529196801955],
    [42.62409547515597, 9.442665632838763],
    [42.62404065871134, 9.442799342888344],
    [42.62395734536312, 9.443001590243517],
    [42.62390112333185, 9.443130649386454],
    [42.62384174719112, 9.443250561070469],
    [42.62377572903483, 9.443360005031025],
    [42.62370082929289, 9.443460422368018],
    [42.62359030793387, 9.443552553189761],
    [42.62348991845082, 9.443618740247473],
    [42.62340664654509, 9.443664251509276],
    [42.62334297677948, 9.44369182689932],
    [42.62329813244187, 9.4437166045361],
    [42.62326310647891, 9.443736562927331],
    [42.62323041856131, 9.44375523323334],
    [42.62319596082882, 9.443774841302774],
    [42.62317382522661, 9.443786725117191],
    [42.62313063415532, 9.443810495758505],
    [42.6230561472053, 9.443850623580516],
    [42.62299873058768, 9.443878198995696],
    [42.62294109118027, 9.443899949098316],
    [42.62288298253106, 9.443908026846435],
    [42.6228208843312, 9.443903563135676],
    [42.62275646634353, 9.443885473102828],
    [42.62269401769392, 9.443863900467457],
    [42.62263130508197, 9.443845441692764],
    [42.622572565757, 9.443827957800281],
    [42.62251252074924, 9.443812925714129],
    [42.62245443190758, 9.443797317230954],
    [42.62239390511348, 9.443779696068265],
    [42.62233004248606, 9.443760076301146],
    [42.62226248214977, 9.443738971273955],
    [42.62219078214792, 9.443716669160972],
    [42.62212021517861, 9.443695288534874],
    [42.62205185187694, 9.443675037290991],
    [42.62198541014194, 9.443655448159429],
    [42.62192103345537, 9.443635870737589],
    [42.62185660969209, 9.443613105045443],
    [42.62179377754743, 9.44358597358627],
    [42.62173155426122, 9.443553037403563],
    [42.62167025827312, 9.443514604061326],
    [42.62160984410014, 9.443475746225689],
    [42.62154798433966, 9.443440741122304],
    [42.6214815837133, 9.443412768941043],
    [42.62141424982597, 9.443394437182222],
    [42.62134779600561, 9.443382101276299],
    [42.6212832875137, 9.443373636409696],
    [42.62121908936558, 9.443367201410778],
    [42.62115265014305, 9.443367778038349],
    [42.62108083915237, 9.443366975561466],
    [42.62100337844453, 9.443364478703041],
    [42.6209234418257, 9.443359945836018],
    [42.62083627380042, 9.443347126821656],
    [42.62074811243443, 9.44333512782832],
    [42.62065762987092, 9.443323639365524],
    [42.62056870884346, 9.443313269441328],
    [42.62047938859315, 9.443303703225626],
    [42.6203893252174, 9.443294717387573],
    [42.62030092845974, 9.443286856903217],
    [42.62021155979049, 9.443279853277966],
    [42.62012435332679, 9.443274007190652],
    [42.62003656949895, 9.443268887814678],
    [42.61994970072837, 9.443264090945581],
    [42.61986522862006, 9.443259607711511],
    [42.61978530805968, 9.443255901341713],
    [42.61971588040543, 9.443252947540275],
    [42.61965721643652, 9.443250167241331],
    [42.6196053223843, 9.443246958446117],
    [42.61955725868538, 9.443243052235188],
    [42.61950925438303, 9.443238591918096],
    [42.61946140258797, 9.443233991661822],
    [42.61941858869928, 9.443225601963583],
    [42.61938428256695, 9.44320526417207],
    [42.61936286387723, 9.443168428992552],
    [42.61935599343799, 9.443113264717072],
    [42.61936035987787, 9.443041454904732],
    [42.61937554952301, 9.442955037544522],
    [42.6193715160954, 9.442838922537982],
    [42.61936873926194, 9.442711009226665],
    [42.61936878666065, 9.44257613468198],
    [42.6193686499691, 9.442438221646601],
    [42.61938902275404, 9.442289658796987],
    [42.61940929484049, 9.442145291920298],
    [42.61942807127112, 9.44201074376236],
    [42.61944479826259, 9.441895909443456],
    [42.61945835796255, 9.441802983423958],
    [42.61947074324546, 9.44172655487959],
    [42.619483590483, 9.441658023348943],
    [42.6194980756296, 9.441585084771104],
    [42.61951577292475, 9.441503750954658],
    [42.6195364215026, 9.441414539123473],
    [42.61963252234248, 9.440902120766141],
    [42.61965442276587, 9.440783415561404],
    [42.61965431689641, 9.440782493935467],
    [42.61966502043721, 9.44071721212898],
    [42.61968508204203, 9.44059123396022],
    [42.61970259089416, 9.440462470091186],
    [42.61971527579679, 9.440330540283057],
    [42.61972556101063, 9.440180517267668],
    [42.61973268421065, 9.440023110662709],
    [42.61973840558156, 9.439861268258641],
    [42.61974410466057, 9.439702305822049],
    [42.61974952653669, 9.439553947256462],
    [42.6197557075975, 9.439414423609657],
    [42.61976215102937, 9.439285298786892],
    [42.61976079960835, 9.4391650087559],
    [42.61975624005402, 9.439051319686552],
    [42.61974850636545, 9.438938975133588],
    [42.61973854908818, 9.438820594335114],
    [42.61973579905495, 9.438697130607393],
    [42.61973489214871, 9.438569014039567],
    [42.61973583213202, 9.438440177114352],
    [42.61973776046691, 9.43831666523945],
    [42.61973981290697, 9.438196650377598],
    [42.61974214474068, 9.438081833520098],
    [42.61974499873553, 9.43797631368485],
    [42.61974847540546, 9.437880485165108],
    [42.61975574599871, 9.437798786885226],
    [42.61977062315572, 9.43772716748799],
    [42.61979660885768, 9.437671505916995],
    [42.61983647409459, 9.437635111899905],
    [42.6198873364884, 9.437623750211527],
    [42.61991767987558, 9.437636853386977],
    [42.61997730293654, 9.437657625038852],
    [42.6200406585443, 9.437690638737754],
    [42.62010521093278, 9.437729189644669],
    [42.62020594382972, 9.437786616463338],
    [42.62027260692045, 9.437810765367399],
    [42.62033588729849, 9.437821991887573],
    [42.62039298295787, 9.43781551464131],
    [42.620438352096, 9.437786420002599],
    [42.62047377775404, 9.437740731662554],
    [42.62049928582179, 9.437683621605688],
    [42.6205180823648, 9.437621967398837],
    [42.62053577152466, 9.437561038724661],
    [42.620548521388, 9.437500473503048],
    [42.62055231487214, 9.437439381368831],
    [42.62054622990203, 9.437381230963391],
    [42.62053845857235, 9.437352160082197],
    [42.6206052493417, 9.43730803595046],
    [42.62099134011365, 9.437090656675549],
    [42.62099078878517, 9.437087655138853],
    [42.6210110209599, 9.437087266385262],
    [42.62105456695465, 9.437088221540165],
    [42.62109995341261, 9.437084752605195],
    [42.6211497195449, 9.437081707577164],
    [42.62120711953351, 9.437078642820273],
    [42.62126767924312, 9.437074399430916],
    [42.62132956304371, 9.437072081087049],
    [42.62138973830195, 9.43706914606909],
    [42.62144690989899, 9.437066234968745],
    [42.62150175077909, 9.437063339081909],
    [42.62155497718977, 9.437061556085245],
    [42.62160703446463, 9.437060030501865],
    [42.62165944829016, 9.437057261933514],
    [42.6217163197259, 9.437053341853368],
    [42.62177631305078, 9.437047392633485],
    [42.62183969396966, 9.437040543627838],
    [42.62190464226208, 9.4370341588247],
    [42.6219660062327, 9.437028762098011],
    [42.62202291018723, 9.437024270112341],
    [42.62207518042679, 9.437021623614076],
    [42.6221229833713, 9.437019438530443],
    [42.62216614580842, 9.437015609119239],
    [42.62220687251849, 9.437012715942069],
    [42.62224604319763, 9.437008413899528],
    [42.62231180304963, 9.437001805063064],
    [42.62252441633337, 9.436991029073097],
    [42.62301801879116, 9.436967994716744],
    [42.62301872478856, 9.436964120450925],
    [42.6230316645245, 9.436963427791833],
    [42.62305900376605, 9.436962068492861],
    [42.6230874291138, 9.43696041516978],
    [42.62311788714153, 9.436964430433461],
    [42.6231539098526, 9.436964278610322],
    [42.62317297575814, 9.436964714252335],
    [42.62321058672966, 9.436964662484629],
    [42.62324956074513, 9.436964414119622],
    [42.62328803166561, 9.436961283323651],
    [42.62334188038926, 9.43695807973632],
    [42.62337543620227, 9.436952764984779],
    [42.62340516480108, 9.436949820579878],
    [42.62342956982059, 9.436950780754822],
    [42.62348020449793, 9.436949767405189],
    [42.62351962064648, 9.436947328534675],
    [42.6235668306679, 9.436940309209888],
    [42.62358462237796, 9.436937945895558],
    [42.62361788334363, 9.436935963834502],
    [42.62365170634927, 9.436932238790156],
    [42.62368407339032, 9.436929310156536],
    [42.62371699402102, 9.436928046057083],
    [42.62373407452526, 9.436926217888677],
    [42.62376805198144, 9.436925180947284],
    [42.62380524298861, 9.436924244495348],
    [42.62384287304527, 9.436922326175843],
    [42.62389974868736, 9.436924517987421],
    [42.6239394538212, 9.436924521275198],
    [42.62397607471947, 9.436924376664788],
    [42.62401266794796, 9.436923781560905],
    [42.624050997718, 9.436922651716566],
    [42.62408803092998, 9.436925073823057],
    [42.62412617380206, 9.436930948488911],
    [42.62416662675048, 9.436937750552504],
    [42.62420475261933, 9.436944267221403],
    [42.62424659511061, 9.436950200551015],
    [42.62428573614196, 9.436954820792732],
    [42.62432196466711, 9.436959691681512],
    [42.62435621958343, 9.43696633793857],
    [42.62438932472541, 9.436973908127579],
    [42.62442754467612, 9.436981935092298],
    [42.62447019704869, 9.43699057634347],
    [42.62451774335305, 9.436996985282486],
    [42.62456834922697, 9.43700234136163],
    [42.62461863822973, 9.437008034546519],
    [42.62466834863979, 9.437014848888044],
    [42.62471769374084, 9.437021682586906],
    [42.6247644107793, 9.437028526528861],
    [42.62481114966102, 9.437035471979106],
    [42.62485731609537, 9.43704255885517],
    [42.62490550176348, 9.437050619419816],
    [42.62495255897851, 9.43705876881759],
    [42.62499783916878, 9.437066439158441],
    [42.62504466263889, 9.437073397091686],
    [42.62509100022722, 9.437078970437954],
    [42.62513924328243, 9.437085308598105],
    [42.62518974334127, 9.437092500173796],
    [42.62523992314314, 9.437100372911299],
    [42.62529218627718, 9.437109195568381],
    [42.62534599183886, 9.437117202887618],
    [42.62540271793242, 9.43712499801897],
    [42.62546160356493, 9.43713177946541],
    [42.62551913884928, 9.437137469232489],
    [42.62557580890549, 9.437142573685033],
    [42.6256267890959, 9.437147133160909],
    [42.62567502568579, 9.437153039306894],
    [42.62572336186282, 9.437160171672399],
    [42.62577077807554, 9.437167547917189],
    [42.62582566587679, 9.437176248784695],
    [42.62588204914523, 9.437183565400053],
    [42.62593544344387, 9.437189324044066],
    [42.62599158637384, 9.437194567760383],
    [42.62604314881565, 9.437198623338784],
    [42.62609225307087, 9.437202758467169],
    [42.62614390581555, 9.437207153526264],
    [42.62619196939901, 9.437211951319577],
    [42.62624182101829, 9.437216497134987],
    [42.62629538774036, 9.437221709573651],
    [42.62635096689404, 9.437227303105194],
    [42.62640859233462, 9.437233054112017],
    [42.62646845505662, 9.437240040889998],
    [42.62652776219309, 9.437248541201688],
    [42.62658735197052, 9.437262014936051],
    [42.62664673218617, 9.43728085052796],
    [42.6267033669148, 9.437305060821934],
    [42.62675997390291, 9.437333854667056],
    [42.62681240342219, 9.437360196976046],
    [42.62686652034487, 9.43738331370397],
    [42.62692272005406, 9.437402737347442],
    [42.62697886947124, 9.437418133477152],
    [42.62703626285057, 9.43743331244182],
    [42.62708466629086, 9.43744740373705],
    [42.62716673981589, 9.43747021061623],
    [42.62735501763493, 9.437523395370778],
    [42.62740428798998, 9.437532458641401],
    [42.62740493099893, 9.437533897379593],
    [42.62742946810118, 9.437539846403439],
    [42.62747972919357, 9.437549593547118],
    [42.62752925926419, 9.437553091324643],
    [42.62757700099942, 9.437553417865072],
    [42.627626068488, 9.437552841884633],
    [42.62767734549003, 9.437555886680324],
    [42.62773220261073, 9.437565893910852],
    [42.62778694029966, 9.437578565220786],
    [42.62784021564882, 9.437592447633875],
    [42.62789163183904, 9.437605522459235],
    [42.62794003965583, 9.437648063946266],
    [42.62796155797184, 9.437699748995467],
    [42.6281945585579, 9.43817049095968],
    [42.6281878722634, 9.438130031871063],
    [42.62820105812611, 9.437901628837007],
    [42.62820423834212, 9.43789971305349],
    [42.62818723269407, 9.437830375958058],
    [42.62817406320993, 9.43773927145617],
    [42.6281700167837, 9.437651964439169],
    [42.62836443606837, 9.43762571270763],
    [42.62858833403667, 9.43764424396022],
    [42.62859767276011, 9.437643883951617],
    [42.62863112792934, 9.437673515436995],
    [42.62869206519947, 9.43771718615189],
    [42.62874853730425, 9.437743965115803],
    [42.62877548124513, 9.437648612679101],
    [42.62881347011542, 9.437312002589492],
    [42.6288073542652, 9.437323757606725],
    [42.62876588604606, 9.437311487570954],
    [42.62869614194186, 9.437305633264222],
    [42.62862554852349, 9.437317138385637],
    [42.62855360142087, 9.437334773062929],
    [42.62848702683731, 9.437359867564078],
    [42.62841574956158, 9.437367981648036],
    [42.62834103247295, 9.437357590196068],
    [42.62826976046581, 9.437331154905026],
    [42.62820329079566, 9.437303113791527],
    [42.62813920672955, 9.437280085546998],
    [42.62807488356557, 9.437263609498023],
    [42.62801011048596, 9.437254911327267],
    [42.62794557828802, 9.437249945814559],
    [42.62788277861374, 9.437243393822655],
    [42.62782156965977, 9.437238691601612],
    [42.62776004128722, 9.437234368889726],
    [42.62769553402917, 9.437231489564725],
    [42.62763145881969, 9.437233875320574],
    [42.62756724136859, 9.437236597936019],
    [42.62750627458994, 9.437239341718952],
    [42.62745066004427, 9.437240106139425],
    [42.62739863375621, 9.437237346057984],
    [42.62734878035822, 9.437234224425593],
    [42.62729867635802, 9.437230710162192],
    [42.62724714882985, 9.437226339526861],
    [42.62719620558556, 9.43722294577713],
    [42.62714904760544, 9.437222923757552],
    [42.6271053462161, 9.437224446416716],
    [42.62706604557133, 9.437223459984793],
    [42.6270289703239, 9.437218008705425],
    [42.62699301489294, 9.437203824675567],
    [42.62695643243824, 9.437185960449314],
    [42.62691859913048, 9.437172936931033],
    [42.62687380427585, 9.437163462286856],
    [42.62682021983505, 9.437157818731418],
    [42.62675844892519, 9.437153103121407],
    [42.62669124692204, 9.43714598633434],
    [42.62662524413913, 9.437137834181968],
    [42.62655800126942, 9.437129723830312],
    [42.62649053718859, 9.43712292398977],
    [42.62641815809528, 9.437116027190466],
    [42.6263395312784, 9.437109597961475],
    [42.62625713461987, 9.437103235753451],
    [42.62617207667397, 9.437095381131623],
    [42.62608520013714, 9.437086688451993],
    [42.62599318651715, 9.437076207006802],
    [42.6258972753634, 9.437064733003044],
    [42.62579807908192, 9.43705146528789],
    [42.62569586349325, 9.437037481679114],
    [42.62559153085775, 9.437022667249092],
    [42.62548390464489, 9.437006418156948],
    [42.62537304872583, 9.436990364408176],
    [42.62526105298738, 9.436973796559295],
    [42.62514928796747, 9.436957722411876],
    [42.62504012738903, 9.43694287151118],
    [42.62493271444951, 9.436928294799102],
    [42.6248271621165, 9.436913891187253],
    [42.62472573900598, 9.436899899389491],
    [42.62462837531058, 9.436885787451603],
    [42.62453221752092, 9.436870601196423],
    [42.62444022908876, 9.436855855826552],
    [42.62434778725898, 9.436840089572289],
    [42.62425712494966, 9.436824643051796],
    [42.62417097025136, 9.436810771527282],
    [42.6240838271841, 9.436796579628968],
    [42.62399748168242, 9.436784797643133],
    [42.62390752320313, 9.436774687694028],
    [42.62381276515463, 9.436767021586983],
    [42.62371676553222, 9.436763233821253],
    [42.62362112724318, 9.436761755768224],
    [42.6235274654173, 9.436762925599414],
    [42.6234389765843, 9.436763729366175],
    [42.62335306351871, 9.436767473559863],
    [42.62326680987319, 9.436772108610274],
    [42.62318315091193, 9.436778910800626],
    [42.62309914914695, 9.436788238055811],
    [42.62301680618055, 9.436795314573995],
    [42.622938469794, 9.436801332468042],
    [42.62286083771956, 9.436804816429174],
    [42.6227831345822, 9.436807116658837],
    [42.62274318106879, 9.436808580020436],
    [42.62266372403923, 9.436811404706608],
    [42.62258307283352, 9.436814662523558],
    [42.62250008398124, 9.436816495993385],
    [42.62241617044903, 9.436819744820029],
    [42.62233078081648, 9.436823739002721],
    [42.62224460077132, 9.436826352838883],
    [42.62215605396911, 9.436829279017916],
    [42.62206717272352, 9.436834292005068],
    [42.62197729873535, 9.436837500962746],
    [42.62188621380178, 9.43683880638422],
    [42.6217946084973, 9.436842669522147],
    [42.62170248565808, 9.436847037815784],
    [42.62161015563181, 9.436851484546951],
    [42.62151834353412, 9.436858004407188],
    [42.62142863980729, 9.436865958302194],
    [42.62133786055947, 9.436874042420122],
    [42.62124841696222, 9.436881203645687],
    [42.62116208009129, 9.436887560167582],
    [42.62107876977471, 9.436891708683813],
    [42.62099765216189, 9.43689332236662],
    [42.62092104223569, 9.436892272698914],
    [42.62085127928253, 9.436889479579882],
    [42.62075675867061, 9.436882434147504],
    [42.62070455618256, 9.436876880954896],
    [42.62065758411944, 9.436865826929811],
    [42.62061089901885, 9.436846970058122],
    [42.62056347998898, 9.436820190360288],
    [42.62051527923879, 9.436786183883722],
    [42.62046955115161, 9.436749959661094],
    [42.62042527036481, 9.436712145911271],
    [42.62038256916419, 9.436674955862925],
    [42.62024964308392, 9.436695660237998],
    [42.61967020561397, 9.436787713463206],
    [42.61966938780277, 9.436784918104813],
    [42.61964810124984, 9.436787611255186],
    [42.61960517660744, 9.436794603823625],
    [42.61956374412692, 9.436801739044405],
    [42.61950384521641, 9.436810558783302],
    [42.61944100090204, 9.436818310664036],
    [42.61908698921077, 9.436856901382306],
    [42.61897079198562, 9.436904582120576],
    [42.61859533262199, 9.437035919041653],
    [42.61859061393423, 9.437031410511013],
    [42.61856572205734, 9.437029081957697],
    [42.61851405808443, 9.437032073506316],
    [42.61848394228567, 9.437035775008361],
    [42.61842444454088, 9.437042230940065],
    [42.61836327064135, 9.437050665103422],
    [42.61829347623158, 9.437063246012027],
    [42.61817979421577, 9.437080082017951],
    [42.61809923523167, 9.437087146880227],
    [42.6180546611309, 9.437090690591139],
    [42.61796804678321, 9.437096994609231],
    [42.61787821829853, 9.437102963642587],
    [42.61778600963633, 9.437110151026836],
    [42.61764419415749, 9.437120721731745],
    [42.61754613214921, 9.437128784824456],
    [42.6174461171704, 9.437136959638758],
    [42.61734665812661, 9.43714618313234],
    [42.61726467380426, 9.43715262349097],
    [42.61718312296355, 9.437158061291059],
    [42.61710375599346, 9.437164151601891],
    [42.61702594309284, 9.437169283018516],
    [42.61693168662456, 9.437177742870002],
    [42.61683872627298, 9.437186145425434],
    [42.61674582160356, 9.437194013948202],
    [42.61665195939214, 9.437201026417604],
    [42.61655970674381, 9.437206835955385],
    [42.616468247566, 9.437212411209106],
    [42.61637694523153, 9.437216167649112],
    [42.61628598931334, 9.43721962270028],
    [42.61619299497927, 9.43722382174597],
    [42.6160980730154, 9.437229494912458],
    [42.61600100327282, 9.437238239341937],
    [42.61590195569055, 9.437248004577357],
    [42.61579915070873, 9.43725647741077],
    [42.61569561729554, 9.437263685304073],
    [42.61558990817291, 9.437270085420536],
    [42.61548309130986, 9.437276879521836],
    [42.61537520934313, 9.437286142664567],
    [42.61526541236568, 9.437295807389919],
    [42.61515412333019, 9.437304364787355],
    [42.61504274201049, 9.437312856958464],
    [42.61493251105936, 9.437319847363206],
    [42.61482226441061, 9.437328163598325],
    [42.61471107406729, 9.43733677175703],
    [42.61459966105213, 9.437344260369859],
    [42.61448923454159, 9.43735172998243],
    [42.61437905316389, 9.437357785295209],
    [42.61427077989217, 9.437364283240029],
    [42.61416377771349, 9.437371533785141],
    [42.61405696215631, 9.437376844837869],
    [42.61395196741767, 9.437378988480761],
    [42.61384908055005, 9.437381950378676],
    [42.61374876161485, 9.437385942076579],
    [42.61364759025969, 9.437394504832209],
    [42.61354126658737, 9.437410090204036],
    [42.61342208588334, 9.43743186897597],
    [42.61328973678608, 9.43745860650718],
    [42.61314325112474, 9.43748605310434],
    [42.61298596168069, 9.437513175787235],
    [42.6128217660353, 9.437534329042951],
    [42.61265081294435, 9.437550510648409],
    [42.61247646671435, 9.437565345101286],
    [42.61229866237262, 9.437577385337276],
    [42.6121175846755, 9.43759061937091],
    [42.61193346891258, 9.437605125763051],
    [42.61174712415905, 9.437619422692613],
    [42.61155823249719, 9.437634848588896],
    [42.61136628599157, 9.437650463904246],
    [42.6111724529445, 9.437665253574579],
    [42.61097710225869, 9.437678687592971],
    [42.61078084079602, 9.437690590854068],
    [42.61058314745217, 9.437703411318271],
    [42.61038488503383, 9.437716115276951],
    [42.61018611524786, 9.437729497461357],
    [42.60998711272228, 9.437743539459566],
    [42.60978797772419, 9.437756222073034],
    [42.60958830230841, 9.437769231768508],
    [42.6093889829777, 9.437782188983082],
    [42.60918954927497, 9.437795344457664],
    [42.60898910382434, 9.437808476412597],
    [42.60878780650645, 9.437822385010834],
    [42.60859039360318, 9.437837757513309],
    [42.60839699036506, 9.437852374418863],
    [42.60820854397276, 9.437867095797428],
    [42.60802738856675, 9.437880320071057],
    [42.6078521828865, 9.437891469077483],
    [42.60768501527684, 9.437902387469197],
    [42.60752627878438, 9.437912359551943],
    [42.60737694751883, 9.437919778114452],
    [42.607237112673, 9.437923983973221],
    [42.60710725138204, 9.43792235693617],
    [42.60698566863943, 9.437915422324306],
    [42.60687291658871, 9.43790480632828],
    [42.60676698472778, 9.437891069740703],
    [42.60666772248859, 9.437877621173326],
    [42.60657819236062, 9.437861771339469],
    [42.60649812850973, 9.437839891548899],
    [42.60642916367713, 9.437806069825845],
    [42.60637229471745, 9.437753714133231],
    [42.60632806287711, 9.43768010433787],
    [42.60629561001984, 9.437589319779356],
    [42.60627346435959, 9.437484154292957],
    [42.60625877076901, 9.437367172504867],
    [42.60624897856545, 9.437244903712623],
    [42.60624169703863, 9.437116802881548],
    [42.60623483800094, 9.436985191216175],
    [42.60622736379263, 9.436851681336487],
    [42.60621670332951, 9.43671412424947],
    [42.6062027939456, 9.436572566708724],
    [42.60618555772971, 9.436427531671336],
    [42.60616814181406, 9.436278839873749],
    [42.60615199879076, 9.436127515911434],
    [42.60613669290464, 9.435974059712047],
    [42.60612281300589, 9.435820264048775],
    [42.60610851077345, 9.435667821114334],
    [42.60609312914522, 9.43551837003387],
    [42.6060793155489, 9.435372519219154],
    [42.60606662910902, 9.435231726065743],
    [42.60605382182149, 9.435094008416387],
    [42.60604147399429, 9.434960657318024],
    [42.60602825618949, 9.434831239045451],
    [42.60601371241505, 9.434706098858101],
    [42.60599643272012, 9.434587927443479],
    [42.6059754891315, 9.434475873077741],
    [42.60595062896276, 9.434369838006084],
    [42.60592201320909, 9.434266558153901],
    [42.60589300092064, 9.43416606497167],
    [42.60586512938397, 9.434066901153804],
    [42.60583742345568, 9.433968240558839],
    [42.60582324826407, 9.43392276492933],
    [42.60579316766218, 9.433828831032145],
    [42.60576070360328, 9.433736793561474],
    [42.60572778752471, 9.433646936374437],
    [42.60568390144721, 9.433511932604342],
    [42.60568052348031, 9.433464657351106],
    [42.60567139841888, 9.4333721933607],
    [42.60567504334852, 9.433278050398107],
    [42.60569867500299, 9.433137438843032],
    [42.60572597126201, 9.433054349035936],
    [42.60575642758975, 9.432982808289717],
    [42.60578731540723, 9.432925104370408],
    [42.60580125847842, 9.432901776777911],
    [42.6058290901839, 9.432853938314851],
    [42.60585560280344, 9.43280775386131],
    [42.60589491387233, 9.43273808041892],
    [42.60592043987353, 9.432691236169164],
    [42.60594776234711, 9.432642006007752],
    [42.6059623536391, 9.432616024294912],
    [42.60599181712614, 9.432563778807234],
    [42.60603655010971, 9.432478361995779],
    [42.60606725182409, 9.432414266061958],
    [42.60609817242332, 9.432344408067374],
    [42.60612658020625, 9.432270117341027],
    [42.60615249818984, 9.432198492563959],
    [42.60617605708115, 9.432127958308685],
    [42.60619834944094, 9.432059659688104],
    [42.6062225984804, 9.431993908256764],
    [42.60624758525738, 9.431926874542746],
    [42.60627432202149, 9.431859804787068],
    [42.60630177519074, 9.431789803042832],
    [42.60632861680898, 9.431715316978289],
    [42.606356295251, 9.431640114414344],
    [42.60638167675445, 9.43156445815349],
    [42.60640551715966, 9.431491620706907],
    [42.60642616955069, 9.431427680330271],
    [42.60644641632717, 9.431369385402226],
    [42.60646526676334, 9.431318827396211],
    [42.60646048462694, 9.431318069129171],
    [42.60643717103848, 9.431397455443982],
    [42.60642980964941, 9.431397308803568],
    [42.60641849327873, 9.431440532433564],
    [42.60639551607172, 9.43152725665626],
    [42.60636553180686, 9.431615039650625],
    [42.60633475030092, 9.43170279845612],
    [42.60630297485398, 9.431787410759075],
    [42.60627452763617, 9.431867182484835],
    [42.60624919578201, 9.431938810452287],
    [42.60622459812518, 9.432003293127709],
    [42.60620105070439, 9.432064614321582],
    [42.60617616363125, 9.432125030886333],
    [42.60614855601766, 9.432192196059916],
    [42.60611704298388, 9.432267734495879],
    [42.60608365161989, 9.432347230301378],
    [42.60604865432283, 9.43242763553979],
    [42.60601197790577, 9.43250539319515],
    [42.60597749840434, 9.43257861284199],
    [42.6059445425788, 9.432643680579687],
    [42.60591372554198, 9.432703905660398],
    [42.60588491890152, 9.432758680629128],
    [42.6058556475858, 9.432808344708619],
    [42.60582540430501, 9.432858159164359],
    [42.60579331368542, 9.432909302452041],
    [42.60576152030468, 9.432963069454592],
    [42.60573229977468, 9.433020753710114],
    [42.60570600154416, 9.433082928339974],
    [42.60568301212736, 9.433148039058437],
    [42.6056661880019, 9.433217340363878],
    [42.60565617039101, 9.433288455622506],
    [42.60565024625245, 9.433360139021616],
    [42.60564534406018, 9.433424990992057],
    [42.60562806900481, 9.433482299520916],
    [42.60559387029975, 9.433530841390805],
    [42.6055453010728, 9.433569157406588],
    [42.60548535370084, 9.433601971643913],
    [42.60542240247607, 9.433632112480122],
    [42.60535805668493, 9.433662271538154],
    [42.6052887687848, 9.433695390023818],
    [42.60521589164166, 9.433731231302898],
    [42.60514082085038, 9.433766430006363],
    [42.60506123968772, 9.433801118563563],
    [42.60497984466871, 9.433832099867175],
    [42.60489358940126, 9.43386113208186],
    [42.60480367564409, 9.433887561711476],
    [42.60471191446708, 9.433912343957575],
    [42.60461726291138, 9.43393806498525],
    [42.604521328537, 9.433964090485992],
    [42.60442547461057, 9.433989642525958],
    [42.60432969816151, 9.434014918427152],
    [42.6042348759791, 9.434037210628944],
    [42.60414195085094, 9.434055382476407],
    [42.6040488666188, 9.434074084696672],
    [42.60395416846528, 9.434094747240298],
    [42.603859519343, 9.434117298549412],
    [42.60376527422029, 9.434144202135462],
    [42.60367063631941, 9.434172291709515],
    [42.60357629734386, 9.434199816073196],
    [42.60348197045338, 9.434227451290946],
    [42.60338563501061, 9.434254201780847],
    [42.60328935003537, 9.4342792315485],
    [42.6031938125666, 9.434305310299079],
    [42.60309813142123, 9.434330830262965],
    [42.60300425742391, 9.434356903679973],
    [42.60290987254055, 9.434383880767582],
    [42.6028129437685, 9.434409286816807],
    [42.60271394065505, 9.434436212420906],
    [42.60261303081513, 9.434461631644551],
    [42.6025133148712, 9.434486533668185],
    [42.60241448378954, 9.434512354603321],
    [42.60231638138652, 9.434538218301581],
    [42.60222109815243, 9.434564431439579],
    [42.60212721650664, 9.434592658551844],
    [42.6020335735026, 9.434620631224059],
    [42.60194129932006, 9.434649970344482],
    [42.60184825989953, 9.434678807143683],
    [42.60175780577556, 9.434704854029965],
    [42.60166846305816, 9.434729543297628],
    [42.60157815844678, 9.434752028186001],
    [42.60149077769534, 9.434773697655253],
    [42.60140395447183, 9.434794843761114],
    [42.60136318050404, 9.434802950718735],
    [42.6012836003216, 9.434821530853984],
    [42.60120983010963, 9.434839384525935],
    [42.60114200145798, 9.434856050829413],
    [42.60107935360046, 9.434872089979796],
    [42.60101992290618, 9.434890871284898],
    [42.60093331394587, 9.434918903649804],
    [42.6008735945454, 9.434940273934858],
    [42.6008117509542, 9.434961590961555],
    [42.60074785844272, 9.43498062771404],
    [42.60071478344903, 9.434989747348219],
    [42.60064806073927, 9.435009279149448],
    [42.60054226976709, 9.435039543433177],
    [42.60046647783092, 9.435063500868486],
    [42.60038692864156, 9.435087501436216],
    [42.60034751566229, 9.435098513658135],
    [42.6002646199281, 9.435121388934387],
    [42.60018259606615, 9.435142983360173],
    [42.60010159272817, 9.435163183459894],
    [42.6000209212618, 9.435182415049738],
    [42.59990127396338, 9.435210239281226],
    [42.59982044737128, 9.435230047383234],
    [42.59973825628322, 9.435252556917712],
    [42.59965672930468, 9.435277704700571],
    [42.5995732531088, 9.435304186823563],
    [42.5994888677271, 9.435329205859059],
    [42.599400183835, 9.435348854891032],
    [42.59931409372683, 9.435362884138913],
    [42.59923387985656, 9.435373390146383],
    [42.59915795597934, 9.435380340744878],
    [42.59907825895663, 9.435392290945558],
    [42.59900002490198, 9.435408958759494],
    [42.59892003737759, 9.435431992334715],
    [42.59883878170353, 9.435463757342536],
    [42.59875922519639, 9.43549780913923],
    [42.59867756131264, 9.435528543339029],
    [42.5985974062973, 9.435553351571199],
    [42.59851439584983, 9.435572892990781],
    [42.59842951567511, 9.435593143532673],
    [42.59834375355218, 9.435617568982156],
    [42.59825420820709, 9.435645546937192],
    [42.59816324250217, 9.43568116615449],
    [42.59806920934552, 9.435721028950143],
    [42.59797451835293, 9.435765913912711],
    [42.59787943469965, 9.435815824512373],
    [42.59778589931434, 9.435865658710526],
    [42.5976933668759, 9.435912854312557],
    [42.59760043246362, 9.435962240269458],
    [42.59750696698455, 9.436013791147646],
    [42.59741600063635, 9.436070681665392],
    [42.5973257590175, 9.436134341155652],
    [42.59723557261917, 9.436202224393655],
    [42.59714992600015, 9.436273789018019],
    [42.59706730856212, 9.436343376532836],
    [42.59698773177781, 9.436409574106046],
    [42.59691058023416, 9.436473154419691],
    [42.5968344821615, 9.436535466485426],
    [42.59675713850302, 9.436598290117404],
    [42.5966795858378, 9.436665804872289],
    [42.59660362497933, 9.436735837053215],
    [42.59652856998653, 9.436807640688656],
    [42.59645345458512, 9.43688049443484],
    [42.59638047802624, 9.436955277588064],
    [42.59630877093432, 9.437031270705978],
    [42.59623482814145, 9.437108377152139],
    [42.59616032711553, 9.437191364246157],
    [42.59608595183866, 9.437278290695302],
    [42.59601176766186, 9.437364977187231],
    [42.59593935164352, 9.437454256015762],
    [42.59586730882226, 9.437544356035541],
    [42.59579294098207, 9.437630378867402],
    [42.59571941124135, 9.437716462007691],
    [42.59564484216131, 9.437801718410965],
    [42.59557071427934, 9.437886851089695],
    [42.59549786491566, 9.437971250094856],
    [42.59542735087815, 9.438054970679438],
    [42.59536122527061, 9.438136425374777],
    [42.5952945143345, 9.438209335569713],
    [42.59522906693889, 9.438269493399758],
    [42.59516010021095, 9.438307112270715],
    [42.59508522699551, 9.438313182003228],
    [42.59500807668027, 9.438289701772661],
    [42.59493279027834, 9.438235998353502],
    [42.59486220719067, 9.438157168630132],
    [42.59480188183996, 9.43806246674262],
    [42.59475592632248, 9.43795365051221],
    [42.59471874602578, 9.437838844312562],
    [42.59468746373557, 9.437722540537058],
    [42.59465741623562, 9.437605989553415],
    [42.59462795975382, 9.437493124628624],
    [42.59459792346724, 9.437385716817198],
    [42.59457006103852, 9.437284145788658],
    [42.59454452106922, 9.437190476364398],
    [42.59451752290083, 9.437105552999626],
    [42.59448663557038, 9.437033479130934],
    [42.59445017988976, 9.436976704201379],
    [42.59439635081451, 9.43684274045547],
    [42.59414605344087, 9.436307934335678],
    [42.59414835080279, 9.436310705292463],
    [42.59413010850669, 9.436317175674706],
    [42.59409501375958, 9.43632569074428],
    [42.59405965728473, 9.436337502342877],
    [42.59402005014602, 9.436349664870535],
    [42.59397901654401, 9.43636021415152],
    [42.59393570757073, 9.436366414333625],
    [42.59389093814845, 9.436361555775752],
    [42.59384706004448, 9.436345675034598],
    [42.59380487793455, 9.436308889899649],
    [42.59376373834893, 9.436262207107029],
    [42.59372404308343, 9.436209206619266],
    [42.59368462195476, 9.436150832280561],
    [42.59364539235263, 9.43610144331004],
    [42.5936079841936, 9.436060279072482],
    [42.59357502488547, 9.436032686842472],
    [42.5935460918448, 9.436024385858202],
    [42.59352045713458, 9.436029717844928],
    [42.59349111976659, 9.436048057989108],
    [42.59345439755637, 9.43607600717587],
    [42.59341063664596, 9.436107146480083],
    [42.5933611492983, 9.436143829986049],
    [42.59331256671744, 9.436177757001735],
    [42.59326757862081, 9.436205141963667],
    [42.59323039588141, 9.436225288552656],
    [42.59320163220153, 9.436231188322255],
    [42.59318488221338, 9.436212930746454],
    [42.59309154204039, 9.436083394170762],
    [42.59309173230849, 9.436080948644705],
    [42.59307752410823, 9.43604617771105],
    [42.59306195450158, 9.436007820865324],
    [42.59303013689331, 9.435927844536478],
    [42.59299620278381, 9.435832397599697],
    [42.59296016362112, 9.43572605147339],
    [42.59290503695438, 9.435551299421403],
    [42.59286937095273, 9.435431152427324],
    [42.59283312439033, 9.435317096038148],
    [42.59279806054816, 9.435208015111025],
    [42.59276229664169, 9.435105144070574],
    [42.59272881320124, 9.435010634492748],
    [42.59270024145971, 9.434926123232628],
    [42.59267934441091, 9.434850177351235],
    [42.59267877647434, 9.434817822206377],
    [42.59267674272333, 9.434754678503845],
    [42.59269482927319, 9.434674150628718],
    [42.59272909404378, 9.434639925480234],
    [42.59277501178416, 9.434627763875268],
    [42.5928297121128, 9.434633013056553],
    [42.59289187042809, 9.434651305131741],
    [42.59295610439633, 9.434676376631414],
    [42.59302646895634, 9.434696919828596],
    [42.59309787703272, 9.434715957110528],
    [42.59317083200465, 9.434732129343162],
    [42.59324405660152, 9.434744575126652],
    [42.59331108445117, 9.434754645731935],
    [42.59337573354415, 9.4347591979421],
    [42.59343548856309, 9.434758471226871],
    [42.5934934873454, 9.43475430960136],
    [42.59354961564008, 9.434750142184457],
    [42.5936015560591, 9.43474661134533],
    [42.59357953311225, 9.434748267536413],
    [42.59344585180026, 9.434761416305456],
    [42.5934463129226, 9.434758637020403],
    [42.59342620676293, 9.434753692256928],
    [42.59338092777246, 9.434746938126628],
    [42.59333526699118, 9.434736103000093],
    [42.59329014481445, 9.434721544199714],
    [42.59323998681035, 9.434709504416208],
    [42.5931896397511, 9.434695551824568],
    [42.59313918114042, 9.434679624645707],
    [42.59308519128571, 9.434665369875859],
    [42.59302811346329, 9.434651337031388],
    [42.59297268698318, 9.434639338494792],
    [42.5929179420889, 9.434627849269734],
    [42.59286479527776, 9.434614864464629],
    [42.59281784637283, 9.434601442561439],
    [42.59277294610831, 9.434589605970165],
    [42.59273058606127, 9.434582084357219],
    [42.59268749914888, 9.434568229334017],
    [42.59248568967183, 9.43445296494081],
    [42.59249106799435, 9.434453015569629],
    [42.59247876060826, 9.434419518749245],
    [42.59245170290156, 9.434349512117233],
    [42.5924222706822, 9.434271170516135],
    [42.59238534514544, 9.434181809653008],
    [42.59234316568077, 9.434084430009596],
    [42.59229683512079, 9.433979571616986],
    [42.5922468595955, 9.433870158889214],
    [42.59219397873494, 9.43375915245305],
    [42.59213996195476, 9.433647053730875],
    [42.59208540295214, 9.433535121543086],
    [42.59203014775511, 9.433421367010672],
    [42.59197624132371, 9.433306812080026],
    [42.59192234477865, 9.433193909411754],
    [42.59186918905596, 9.433081666816797],
    [42.59181741777194, 9.432972239149473],
    [42.59176669592917, 9.432862688385827],
    [42.59171971740148, 9.432747696759929],
    [42.59168068742087, 9.432629957097571],
    [42.59165535136338, 9.432505788266079],
    [42.59164778862404, 9.432377468805081],
    [42.59166236239401, 9.432249029282019],
    [42.59169871356626, 9.432120960622127],
    [42.59175486826155, 9.432000674956424],
    [42.59182694687352, 9.431891830104117],
    [42.59190895325885, 9.431794322232573],
    [42.59199668508884, 9.431707188044093],
    [42.59208511677099, 9.431627718149715],
    [42.59216840258139, 9.431550196712038],
    [42.59224372146613, 9.431467968488265],
    [42.59230236847318, 9.431380035661089],
    [42.59233905128984, 9.431283630982826],
    [42.59235320327846, 9.431175428974965],
    [42.59234229528226, 9.431061368366745],
    [42.59230919460553, 9.430946294473319],
    [42.59225907179945, 9.430831053828397],
    [42.5921946995625, 9.430723039476753],
    [42.59212162784861, 9.430623344175867],
    [42.59204680838685, 9.430526539903955],
    [42.59197296684091, 9.430434344849306],
    [42.59190177397454, 9.430342885696859],
    [42.59183192651166, 9.430250846957339],
    [42.5917631538824, 9.43016147586029],
    [42.59169273801609, 9.430076063131292],
    [42.59161870540105, 9.429999176584875],
    [42.59154105770827, 9.42993205790134],
    [42.59145195978471, 9.429871229098081],
    [42.59135814180608, 9.429814821387753],
    [42.59126974795985, 9.429750580088909],
    [42.59118930379732, 9.42967297299527],
    [42.59112815420881, 9.429578816453965],
    [42.59108977463795, 9.429466753898369],
    [42.59108566681592, 9.429400638897157],
    [42.59107641741018, 9.42927082726532],
    [42.5910817605839, 9.429134223144084],
    [42.59109512082837, 9.428996097467982],
    [42.59112009063725, 9.428788960518618],
    [42.59112543823981, 9.428724565687787],
    [42.59113629785947, 9.428594211295595],
    [42.59114684383646, 9.428465991212819],
    [42.59115829417122, 9.428342948497063],
    [42.59117213128297, 9.428219354377873],
    [42.59119082150422, 9.428101519330111],
    [42.5912200715895, 9.427990993061732],
    [42.59128194311565, 9.427839695945263],
    [42.59135001282216, 9.427756207386299],
    [42.5914299655438, 9.42768076942343],
    [42.59151395668675, 9.427616172587372],
    [42.59159716616969, 9.427554763808223],
    [42.5916778490275, 9.427501767672553],
    [42.59176119570611, 9.427466798132508],
    [42.59184730288117, 9.427454180392992],
    [42.5919354437002, 9.427471338506406],
    [42.59202325414975, 9.427516261158564],
    [42.59210673449864, 9.427577141004129],
    [42.59218472196477, 9.427654561446552],
    [42.59225197840014, 9.427743663527908],
    [42.59230639574799, 9.427840324421998],
    [42.59235248138788, 9.427939368722599],
    [42.59239948707965, 9.428024783619312],
    [42.59245579406242, 9.42809008115216],
    [42.59252840532417, 9.428131066891719],
    [42.59261274266651, 9.42814608730767],
    [42.5927027437565, 9.428144389386377],
    [42.59280046086315, 9.428133452724236],
    [42.59290195319745, 9.428118252032414],
    [42.59300542184201, 9.428105498369376],
    [42.59311325763859, 9.428091015415394],
    [42.59321913800184, 9.428074642748914],
    [42.59331946380462, 9.428054974328417],
    [42.59341244565267, 9.428026675900881],
    [42.59349443844309, 9.427989692693917],
    [42.59356723830953, 9.427946419059241],
    [42.59362703395262, 9.427896263156049],
    [42.59367015731682, 9.42784517823373],
    [42.59365114095557, 9.427870190731506],
    [42.59352991779358, 9.427993767218714],
    [42.59352573899788, 9.427988054500368],
    [42.59349710919062, 9.427993169350046],
    [42.59343822418447, 9.428006696285555],
    [42.59337102549652, 9.42801828999866],
    [42.59329392183827, 9.428031640076222],
    [42.59320641903658, 9.428048673067586],
    [42.59310970982663, 9.428066534709235],
    [42.5930073016939, 9.428081765895074],
    [42.59290412170127, 9.428094286180841],
    [42.59280200559751, 9.4281031747849],
    [42.59270366396063, 9.428104791654516],
    [42.59260969136179, 9.428093558967817],
    [42.59252457713583, 9.428061755342453],
    [42.59244651248018, 9.428004118210451],
    [42.59237558939206, 9.427923706233752],
    [42.59230951934321, 9.427827068571945],
    [42.59224125364888, 9.427728333857047],
    [42.59216963575637, 9.427635551189411],
    [42.59209141841131, 9.427553863521116],
    [42.5920086664902, 9.427487652721327],
    [42.59192244454617, 9.427444066527041],
    [42.59183390878782, 9.427425962566023],
    [42.59174537754022, 9.427433972353747],
    [42.59165959601593, 9.427467843696427],
    [42.59157540278179, 9.427519271465098],
    [42.59149057311605, 9.427582672334481],
    [42.59140764426325, 9.427655507003573],
    [42.59132954198108, 9.427738040447377],
    [42.59126371890942, 9.427832106255059],
    [42.59121480338212, 9.427938878614327],
    [42.59118079589813, 9.428058168623478],
    [42.59115386935459, 9.428188029624454],
    [42.59113527015763, 9.428318371636996],
    [42.59111806574875, 9.428449876015943],
    [42.5911014870672, 9.428586078731916],
    [42.59108830949666, 9.428727024156197],
    [42.59107221173626, 9.428876967712856],
    [42.59105894118043, 9.429030204729747],
    [42.59105155772963, 9.429181959372302],
    [42.59105637969246, 9.429325354024066],
    [42.5910787384548, 9.429457097362217],
    [42.59112072123655, 9.429572379647732],
    [42.59118109755717, 9.429667588781459],
    [42.59125466221587, 9.429744746654954],
    [42.59133659033103, 9.429808186103642],
    [42.59142140830318, 9.429870079533284],
    [42.59150851612539, 9.429936436954117],
    [42.59159526493529, 9.430011677373772],
    [42.59168060131991, 9.430096346383779],
    [42.59176555815353, 9.43018956364994],
    [42.59184850407845, 9.430291384335169],
    [42.59193230318921, 9.430396459461816],
    [42.59201246892531, 9.430503717349911],
    [42.5920880132379, 9.430609889869958],
    [42.59215798803197, 9.43071357803576],
    [42.59221893595763, 9.430821733496284],
    [42.59226830069503, 9.430935012337855],
    [42.59229990391798, 9.431054273984584],
    [42.59230699691388, 9.431176550772991],
    [42.59228560997857, 9.431294639075256],
    [42.59223956527593, 9.431403105683785],
    [42.59217481724434, 9.431499677138804],
    [42.59209897450167, 9.43158666247575],
    [42.59201830185065, 9.431671133265871],
    [42.59193617020364, 9.431759819197294],
    [42.59185593749739, 9.431855818831966],
    [42.59182103386895, 9.431909881151096],
    [42.59175261944737, 9.432021125350424],
    [42.59169770268799, 9.432141975805708],
    [42.5916600181782, 9.43226952368963],
    [42.59164475848717, 9.432401579534853],
    [42.59165181754646, 9.432537101180984],
    [42.59168172224879, 9.432671499001289],
    [42.59172826156296, 9.432805328987094],
    [42.59178648894196, 9.432939241952392],
    [42.59185001795835, 9.433075510817204],
    [42.5919157548744, 9.433214090079328],
    [42.59198062589802, 9.433352726085056],
    [42.59204490855733, 9.433492142418331],
    [42.59210949041142, 9.433632477342087],
    [42.59217391979202, 9.433772680088179],
    [42.59223775427498, 9.433913368667282],
    [42.5923336631102, 9.43412590682483],
    [42.59239664477062, 9.434267922317838],
    [42.59245964903645, 9.434409601731694],
    [42.59252143625319, 9.434551324293016],
    [42.59258008331252, 9.434693912323826],
    [42.59263586942259, 9.434836201322174],
    [42.59268664893109, 9.434980367571178],
    [42.59273369418334, 9.435123925927334],
    [42.59277867253552, 9.435269852533729],
    [42.59282271346569, 9.435413769759686],
    [42.59286689293086, 9.435554746353795],
    [42.59291212960537, 9.435691173305445],
    [42.59295910715971, 9.435822201149611],
    [42.59300598303473, 9.43595163500752],
    [42.59305516319311, 9.436078882139132],
    [42.59310497931172, 9.436206360156365],
    [42.59315563107164, 9.4363320697185],
    [42.5932092232827, 9.436453050382895],
    [42.59326845313688, 9.436566507541109],
    [42.59333327494933, 9.436673325578109],
    [42.59340509733086, 9.436772324296935],
    [42.59348363795977, 9.436861246875726],
    [42.59356323307032, 9.43694269271008],
    [42.59364549727439, 9.437015589800749],
    [42.5937292353639, 9.437082346602388],
    [42.59381160821115, 9.437146165581821],
    [42.59389232437945, 9.437207837737411],
    [42.59397066939059, 9.437271140661659],
    [42.59404504436899, 9.437338443077197],
    [42.59411291814389, 9.437409843832027],
    [42.59417237933577, 9.437484233871356],
    [42.5942208017361, 9.437555300847613],
    [42.59425760425142, 9.43761874896602],
    [42.59429431481453, 9.437762038751259],
    [42.59441432587174, 9.438389471462758],
    [42.59441278022187, 9.438387189143771],
    [42.59439713845779, 9.438418114789471],
    [42.59438135940805, 9.438451540418363],
    [42.59435196416428, 9.438519135785397],
    [42.59432617131815, 9.438590949163485],
    [42.59429896090319, 9.438708043265503],
    [42.59430329426417, 9.438787817144037],
    [42.59432509815286, 9.438861638236981],
    [42.59436499649485, 9.438927758507999],
    [42.59441872248857, 9.438985166995701],
    [42.59447531995062, 9.439036270527678],
    [42.59453174588472, 9.439089309797749],
    [42.5945779004479, 9.439146389804007],
    [42.59460645737769, 9.439204782398015],
    [42.59462908658314, 9.439262446314823],
    [42.5946488348549, 9.439309669245029],
    [42.59467158120925, 9.439337239280039],
    [42.59470324964434, 9.439346180026277],
    [42.59474255818216, 9.439337840106548],
    [42.594788873222, 9.439315700710267],
    [42.59484166090973, 9.439287357626768],
    [42.59490280253411, 9.439256186165185],
    [42.59496758707536, 9.439225162987615],
    [42.59503370860126, 9.439196974813761],
    [42.59509982910512, 9.439174667726121],
    [42.59516348718014, 9.439159863454583],
    [42.59522148893542, 9.439153150850951],
    [42.59527243944576, 9.439159297817291],
    [42.59531537058248, 9.439181748084696],
    [42.5953463786439, 9.439218719380726],
    [42.59536375837257, 9.439262479841291],
    [42.59537422863847, 9.439302371118954],
    [42.59546380061523, 9.439303486085155],
    [42.59549910022813, 9.439284588259063],
    [42.59550127296354, 9.43928305535538],
    [42.5955222322725, 9.439272471587165],
    [42.59556660275335, 9.439251665416315],
    [42.59561745834532, 9.439231162680525],
    [42.59567247165181, 9.439213214310858],
    [42.5957317460454, 9.439196792759279],
    [42.59578753553512, 9.439181416892215],
    [42.59583882582452, 9.439167147165728],
    [42.59588782055769, 9.43915374831934],
    [42.59593410897139, 9.439141371050791],
    [42.59598088880878, 9.439133832183728],
    [42.59603072253699, 9.439129308386665],
    [42.59608256374134, 9.439128847474191],
    [42.59613428443444, 9.439130265482801],
    [42.59618592585875, 9.439131930536245],
    [42.59623343683808, 9.439136281156697],
    [42.59627511341484, 9.439142710274735],
    [42.59631277215058, 9.439158273470582],
    [42.59634428083741, 9.439181802786445],
    [42.59636805598019, 9.439208567249167],
    [42.59634605688287, 9.439195307829914],
    [42.59621150855132, 9.439144250664187],
    [42.59621213591004, 9.439145928510113],
    [42.59618951704803, 9.439146907563016],
    [42.59614399280064, 9.439149947476977],
    [42.59609779986473, 9.439151861792491],
    [42.59604775087418, 9.439152923074705],
    [42.59599258191688, 9.439155141559084],
    [42.59593439487338, 9.439157167502982],
    [42.5958768112061, 9.43916758075907],
    [42.59581795575327, 9.439183587147481],
    [42.59575798211703, 9.439203330785427],
    [42.59569558268304, 9.439227892508558],
    [42.59562865891115, 9.439251668779159],
    [42.59556186951065, 9.439276713489246],
    [42.59549841437002, 9.439303973807787],
    [42.59544277504196, 9.439329962297155],
    [42.59540180667664, 9.439346050153285],
    [42.59532331181951, 9.439271713007203],
    [42.59531809545623, 9.43921848420934],
    [42.59531600040246, 9.439220285599626],
    [42.59530510138381, 9.439198117652591],
    [42.5952810470916, 9.439158670193905],
    [42.59524702492115, 9.439136773888031],
    [42.59520172142739, 9.439132178453631],
    [42.59514905119712, 9.439142088201462],
    [42.59509291171983, 9.43915998627727],
    [42.59503349584291, 9.439176833169508],
    [42.59497418150506, 9.439194976264083],
    [42.59491854617311, 9.439213561987424],
    [42.59486670576777, 9.439234870743226],
    [42.59482028944286, 9.439258145235966],
    [42.59478124496879, 9.439276314152584],
    [42.59476764051126, 9.4392813735586],
    [42.59472148101347, 9.439301496691559],
    [42.59469489207383, 9.439314067399659],
    [42.59466766609994, 9.43933507586055],
    [42.59463931410554, 9.439366730375584],
    [42.59462886211805, 9.439392671984626],
    [42.59460761631116, 9.439445418629521],
    [42.59458884367218, 9.43951034391201],
    [42.59457033977825, 9.439586920901522],
    [42.59454947235491, 9.43967405164226],
    [42.59452568806294, 9.439767493270089],
    [42.59449725932627, 9.439863538042561],
    [42.59444996779122, 9.440011669021594],
    [42.59441731339923, 9.440111664213649],
    [42.59438280399168, 9.440214839801353],
    [42.59434861713494, 9.440323819113775],
    [42.59431435109013, 9.440441163253141],
    [42.59428458583972, 9.440564028051385],
    [42.59425927445829, 9.440693433800673],
    [42.59424228440275, 9.440831160903869],
    [42.59423413699396, 9.44097378997736],
    [42.59423723646298, 9.441121455535059],
    [42.59424744480101, 9.441271737240339],
    [42.59426286675543, 9.441422720523823],
    [42.59428031768861, 9.441573711966409],
    [42.59429435482728, 9.441722868562227],
    [42.59430888282374, 9.441869069725552],
    [42.59432107347128, 9.442009888141282],
    [42.59433391391799, 9.442142640653206],
    [42.59434792735808, 9.442267754841028],
    [42.59436233959583, 9.442378860276015],
    [42.59437439204933, 9.442472326341951],
    [42.59439085950964, 9.442547913169886],
    [42.59441029599682, 9.44260828808385],
    [42.59443972622619, 9.44265074006946],
    [42.59448249992403, 9.442674866267556],
    [42.59453447631824, 9.442680411094527],
    [42.59459681458367, 9.442666566236714],
    [42.59466480675533, 9.442642686805101],
    [42.59474098079918, 9.442616272996114],
    [42.59482355426359, 9.442589094676425],
    [42.59491219241152, 9.442561669029681],
    [42.59500762784443, 9.442531678666336],
    [42.59510553337033, 9.442496740433507],
    [42.59520267687529, 9.442457912035593],
    [42.59529999110084, 9.442417574011856],
    [42.59539764285995, 9.442375709662672],
    [42.59549620422381, 9.442334570805212],
    [42.595598250847, 9.442293226174327],
    [42.59570255436846, 9.442249171377302],
    [42.59580327947801, 9.442203095554005],
    [42.59590292767593, 9.44215674404356],
    [42.59599878805994, 9.442112382684655],
    [42.59609527640048, 9.442068163372243],
    [42.59619149929678, 9.442026736168639],
    [42.59628237667489, 9.441978854378055],
    [42.59636938143084, 9.441924183134716],
    [42.59644996525959, 9.44186584662066],
    [42.59652697267426, 9.441799752325652],
    [42.59660071684817, 9.441733051733188],
    [42.59667374141625, 9.441664130682717],
    [42.59674610060924, 9.441591541504922],
    [42.5968176520119, 9.441519827640001],
    [42.59689150992978, 9.441446873484406],
    [42.59696166951586, 9.441375613982744],
    [42.59702804124338, 9.441306650728801],
    [42.5970913531477, 9.441240335877877],
    [42.59715158488434, 9.44117734004424],
    [42.59721124696899, 9.441119595384116],
    [42.59726861583772, 9.441067676899429],
    [42.59732314949442, 9.441022677143993],
    [42.59737151891969, 9.441016621042763],
    [42.59759708298957, 9.441049507664822],
    [42.59759350623565, 9.441052160235255],
    [42.59760772324992, 9.441085013701111],
    [42.59763745686485, 9.441150508755095],
    [42.59766981385798, 9.441217962983634],
    [42.59770795616195, 9.441285163237799],
    [42.59774491571732, 9.441348070170745],
    [42.59778245381163, 9.441405962463039],
    [42.59781729107164, 9.44145687299593],
    [42.59785089280187, 9.441503428508989],
    [42.59788923223189, 9.441549153435142],
    [42.59792766978206, 9.441592445835663],
    [42.5979691712577, 9.441637274395111],
    [42.59801164400615, 9.441681666837296],
    [42.59805340477536, 9.441725897303627],
    [42.59809593479879, 9.441772109500679],
    [42.59813639965194, 9.441817232598634],
    [42.5981742329486, 9.441860271971093],
    [42.59821106586709, 9.441899950009901],
    [42.59824783302647, 9.441937473169277],
    [42.59828441884564, 9.441976263539337],
    [42.59832465037309, 9.44201848978885],
    [42.59836747045012, 9.442067321477612],
    [42.59841368350958, 9.442119398667003],
    [42.59846423343294, 9.442173351865906],
    [42.59851407407842, 9.442227194122481],
    [42.59856137432072, 9.442277329007192],
    [42.59860438645595, 9.442325824119566],
    [42.59864564904806, 9.442374702700716],
    [42.59868936557338, 9.442426082483037],
    [42.5987358832981, 9.442481179946595],
    [42.59878710219309, 9.442538743799949],
    [42.59884121696103, 9.442597673906132],
    [42.59889567469675, 9.442656538354502],
    [42.59894867220778, 9.442712089728225],
    [42.59900024164877, 9.442764681338057],
    [42.599025705088, 9.442787366723461],
    [42.59907308860362, 9.44283087100316],
    [42.59911738037041, 9.442868975883624],
    [42.59916094880424, 9.442903173929215],
    [42.5992214287201, 9.442949760762138],
    [42.59926520391502, 9.442984650880042],
    [42.59931474106784, 9.443022838580337],
    [42.59937035573154, 9.443066697806644],
    [42.59943224808628, 9.443115093515081],
    [42.5994972696702, 9.44316515775931],
    [42.59956307474785, 9.443214358869533],
    [42.5996288370662, 9.44326246591862],
    [42.59969600706194, 9.443308702052025],
    [42.59976189331407, 9.44335329769623],
    [42.59979157238219, 9.443376098812136],
    [42.59985575351262, 9.443422921389159],
    [42.59992132453795, 9.443470504314996],
    [42.59998888291206, 9.443520568158108],
    [42.60009754479899, 9.443600823664736],
    [42.60017233895447, 9.443658045099955],
    [42.60024562666247, 9.443718540876555],
    [42.60031847077129, 9.443780002364344],
    [42.60038999590582, 9.443839285569929],
    [42.60045943389709, 9.443892862652996],
    [42.6005280228587, 9.443939074769405],
    [42.60059052980482, 9.443976864203835],
    [42.60064917314335, 9.444007801210613],
    [42.6007090167998, 9.444034330056239],
    [42.60077032964355, 9.444052305246238],
    [42.60083341592244, 9.44406054243715],
    [42.60089829891449, 9.444057917428589],
    [42.60096580434332, 9.444044694797098],
    [42.60103477121394, 9.444022690316704],
    [42.60110686465276, 9.443994401909851],
    [42.60118080606977, 9.443963269100607],
    [42.60125408326682, 9.443935807074771],
    [42.6013267258248, 9.443917823867807],
    [42.60140057659411, 9.443916708538808],
    [42.60147287756618, 9.443934877827898],
    [42.6015474290281, 9.443969108756084],
    [42.60162188610843, 9.444021036335513],
    [42.60169529668162, 9.444084077760984],
    [42.60177062982387, 9.444151479728687],
    [42.60184602979075, 9.444219651049563],
    [42.60192105007018, 9.444287877309959],
    [42.60199435026948, 9.444353910736726],
    [42.6020627999058, 9.444420717882394],
    [42.60212589288541, 9.44449316313014],
    [42.60218341606619, 9.444574612031841],
    [42.60223257372792, 9.444665293071891],
    [42.60227256626654, 9.444765627906838],
    [42.60229738282049, 9.444873287028178],
    [42.60231080434328, 9.444979018478984],
    [42.60231783530425, 9.445081872187091],
    [42.60232088081345, 9.445178068846541],
    [42.60232685273171, 9.445268445082391],
    [42.60233104412494, 9.445350164616555],
    [42.60233481034539, 9.445420870613946],
    [42.60236232699911, 9.445443599738425],
    [42.60251754636104, 9.445499127805052],
    [42.6025217921155, 9.445498431839912],
    [42.60255263657969, 9.445481772180933],
    [42.60261601442482, 9.445445767018411],
    [42.60268610389311, 9.445408744484901],
    [42.60276086168435, 9.445370671459459],
    [42.60283760531225, 9.445329762790427],
    [42.60291603061093, 9.445292395479697],
    [42.60299703689275, 9.44525774769131],
    [42.60307465332652, 9.445230551575357],
    [42.60315011627694, 9.445209203992043],
    [42.603222015181, 9.44518573716908],
    [42.60329370500268, 9.445155916336239],
    [42.60337496487674, 9.445112754535741],
    [42.60346478259085, 9.445056648535511],
    [42.60357024833219, 9.444989685699429],
    [42.60368866392432, 9.444917520122011],
    [42.60380957640491, 9.444846798861368],
    [42.60393605985078, 9.444778537220653],
    [42.60406005671159, 9.444713617831436],
    [42.60418262709977, 9.444649932340701],
    [42.60430422439621, 9.444587208463556],
    [42.60441951949664, 9.444528758094481],
    [42.60453314145602, 9.444475055238915],
    [42.60464327820766, 9.444430656267476],
    [42.60475047196191, 9.444394708590128],
    [42.6048560522586, 9.444365511236294],
    [42.60496156163664, 9.444340177310329],
    [42.60506965947389, 9.444315449535159],
    [42.60518015129444, 9.444292871567393],
    [42.60529377476814, 9.444271221610812],
    [42.60540603310034, 9.44424958465968],
    [42.60551341281474, 9.444229260360242],
    [42.60561662328422, 9.444208671792687],
    [42.60571739953807, 9.444190090890167],
    [42.60582027670641, 9.444171638142976],
    [42.60592654162701, 9.444149221827034],
    [42.60603336797392, 9.444125511880507],
    [42.60613976259615, 9.444098512266207],
    [42.60624856565621, 9.444072342322501],
    [42.60636087385002, 9.444049400188302],
    [42.606474727106, 9.44402883003243],
    [42.60659081901134, 9.444012642299541],
    [42.60670739318095, 9.44399922312242],
    [42.60682231890779, 9.443987745750277],
    [42.60693643855206, 9.443978650825978],
    [42.60704885068974, 9.443970376747455],
    [42.60715968587017, 9.443962441365315],
    [42.60726692366138, 9.443956947776687],
    [42.60737274809084, 9.443951957214141],
    [42.6074787779114, 9.443946762368853],
    [42.60758326472767, 9.44394248441572],
    [42.60768792012639, 9.443938061690789],
    [42.60779253519775, 9.443933861261728],
    [42.60789281568946, 9.443929997698852],
    [42.60799102421699, 9.443927208639389],
    [42.60808708788468, 9.443924593545496],
    [42.60817958784149, 9.443921407367366],
    [42.60827099211242, 9.443917422232893],
    [42.6083610700507, 9.443911953544982],
    [42.60844956983259, 9.443902507745252],
    [42.60853769869946, 9.443891301044143],
    [42.60862358868387, 9.443877056522172],
    [42.608667008521, 9.443868790825315],
    [42.60874657688945, 9.443853606457122],
    [42.6088215070661, 9.443839738252695],
    [42.60889215785639, 9.443826781069669],
    [42.60895723203682, 9.443816069099737],
    [42.60899249145073, 9.443802220381935],
    [42.60920246384366, 9.443712519180886],
    [42.60920372175651, 9.443712417959309],
    [42.60922633197089, 9.443700263011985],
    [42.60925400111736, 9.4436855588475],
    [42.6093083183686, 9.443655627636293],
    [42.60936838143205, 9.443623484558287],
    [42.60943302161587, 9.44358955083664],
    [42.60949769078924, 9.443554292038602],
    [42.60959106585005, 9.4435020734819],
    [42.60964687750296, 9.443457083089152],
    [42.60969627363913, 9.443396445948013],
    [42.60974069516009, 9.443320110568909],
    [42.60978310351673, 9.443225532842867],
    [42.60982531434416, 9.443123680955653],
    [42.60991098168749, 9.442943076269955],
    [42.60999331879579, 9.442812927638347],
    [42.61007901161973, 9.442705065318224],
    [42.61012008987043, 9.442672808967345],
    [42.61019322257209, 9.442619802662401],
    [42.61027207757108, 9.442581220458035],
    [42.6103572542188, 9.442549407354614],
    [42.61044860611923, 9.442519525590004],
    [42.61054297906067, 9.442491412532993],
    [42.61063563131429, 9.442460239798471],
    [42.61072391434561, 9.442431836264278],
    [42.61080438549169, 9.442411085227493],
    [42.61087464302334, 9.442396014713239],
    [42.61093477127234, 9.44238584006554],
    [42.61098753049029, 9.442381217615651],
    [42.61103291352231, 9.442380909083274],
    [42.61107591672477, 9.442380242492092],
    [42.61112797041789, 9.442381890009733],
    [42.61118504085382, 9.442382275909335],
    [42.61125140444882, 9.442384138747201],
    [42.61132858940763, 9.442395626078202],
    [42.61140529369261, 9.442416788872242],
    [42.61148314485282, 9.442452928108722],
    [42.61156171266485, 9.442502783373911],
    [42.61164088582613, 9.442563560704789],
    [42.61172337013534, 9.442631857808054],
    [42.611810422639, 9.442704364114565],
    [42.61190151837287, 9.442777197375054],
    [42.61199715223027, 9.442848399628524],
    [42.61209469930328, 9.442925416235418],
    [42.61219482850782, 9.443001562905412],
    [42.61229755880448, 9.443078400395807],
    [42.61240125647603, 9.443155946651716],
    [42.61250782862166, 9.44322763100115],
    [42.61261735197909, 9.443298875416563],
    [42.61272809707228, 9.443366661328618],
    [42.61284110515196, 9.443429236762849],
    [42.61295747337903, 9.443486760736056],
    [42.61307490229093, 9.44354014818305],
    [42.61319284275105, 9.443593557874284],
    [42.61331008814002, 9.443651222293942],
    [42.61342215968951, 9.443714907148889],
    [42.61352716859883, 9.443788333942098],
    [42.6136238007606, 9.443870325749472],
    [42.61371092608331, 9.443955161394358],
    [42.6137934000568, 9.44403664139357],
    [42.61387562033828, 9.444102674757886],
    [42.61396086522375, 9.444143248576404],
    [42.61405183235807, 9.44414797831613],
    [42.61414911300889, 9.444132741864859],
    [42.61425220158382, 9.444106856595726],
    [42.61435917626102, 9.44408056498345],
    [42.61446917225418, 9.444069479308306],
    [42.6145785589638, 9.444061991981195],
    [42.61468422762424, 9.444054939915105],
    [42.61478614873804, 9.444047524203516],
    [42.61488121254589, 9.444043578706424],
    [42.61496864215875, 9.444042367798151],
    [42.615048157589, 9.444037665633859],
    [42.61511861923502, 9.444019706153526],
    [42.6151787756013, 9.443974660997052],
    [42.61522638389917, 9.44390260795392],
    [42.61526186850226, 9.443810285965842],
    [42.61529328385379, 9.44371098048617],
    [42.6153291604473, 9.443617458399089],
    [42.6153768598109, 9.443538023066166],
    [42.61543866600067, 9.443478957922663],
    [42.61550913699819, 9.443441574397051],
    [42.61558073883887, 9.443421715896562],
    [42.61563513736623, 9.443422264337128],
    [42.61593035959235, 9.443432768883255],
    [42.61593248317826, 9.443434156003709],
    [42.61595861660971, 9.443437454365014],
    [42.6160134274883, 9.443441634530739],
    [42.61607171207516, 9.443441973426451],
    [42.61635023356494, 9.443402086273366],
    [42.61639052889227, 9.443347319681497],
    [42.61639613825968, 9.443362688387888],
    [42.61641754590753, 9.443357001011766],
    [42.61645586693371, 9.443353456887616],
    [42.61664822592881, 9.443339557061424],
    [42.61664814181451, 9.443338402889719],
    [42.61667377608505, 9.443335947531892],
    [42.61672558922715, 9.443331443629798],
    [42.61678601778491, 9.443325305578071],
    [42.6168569680209, 9.443318343303117],
    [42.6169337376019, 9.443309337427769],
    [42.61700959796148, 9.443297888471989],
    [42.61708473187565, 9.44328327504109],
    [42.61715482851746, 9.443268703700523],
    [42.61718840035635, 9.443264813341528],
    [42.61725692427591, 9.443255974683757],
    [42.61732690807121, 9.443250740676396],
    [42.6173959250997, 9.443250532915773],
    [42.61750727993768, 9.443253133942774],
    [42.61757909736582, 9.443256544354105],
    [42.61764414613636, 9.443260660064357],
    [42.61770082767624, 9.443264900977676],
    [42.61775199432378, 9.443268580851619],
    [42.61780400755514, 9.443271088285076],
    [42.61786126390833, 9.443272829322479],
    [42.61792711664719, 9.443273391267361],
    [42.61799995761359, 9.443273535367137],
    [42.61807957449771, 9.443273607361892],
    [42.61816416634495, 9.443273407686036],
    [42.61825143023918, 9.443272994062553],
    [42.61834220842967, 9.443272404117918],
    [42.6184348371557, 9.443271972777474],
    [42.61852639293478, 9.443271595317052],
    [42.61861677930083, 9.443271294910177],
    [42.61869845781892, 9.443271224863427],
    [42.61877006262272, 9.443271501027677],
    [42.61883453863206, 9.443271964057221],
    [42.6188951392588, 9.443272181593777],
    [42.61895748178845, 9.443272079737593],
    [42.619022013563, 9.443271883757614],
    [42.61908540692126, 9.443271894247399],
    [42.61914761068059, 9.443272593785977],
    [42.61920420069969, 9.443274190762983],
    [42.61925653525383, 9.443271311759396],
    [42.61930630623969, 9.44325942292468],
    [42.61934880408506, 9.443233706565687],
    [42.61934762780812, 9.443086352515422],
    [42.61935920277693, 9.442532835424396],
    [42.6193598832367, 9.442531444794513],
    [42.61936848690668, 9.442471972777399],
    [42.61938557866598, 9.442353107028639],
    [42.61940331606534, 9.442232109448872],
    [42.61942055787082, 9.442114533447576],
    [42.61943654549977, 9.442007104520235],
    [42.61945165175876, 9.441911565648169],
    [42.61946613869305, 9.441832438051087],
    [42.61948131830628, 9.441764847522569],
    [42.61949780778596, 9.441699051647628],
    [42.61951680809927, 9.441628920649091],
    [42.61953851359479, 9.441545127496132],
    [42.61956125728327, 9.441449134109892],
    [42.61958491995113, 9.441343033706197],
    [42.61960857486746, 9.441229222511152],
    [42.61962405528413, 9.441100534110674],
    [42.61963950314745, 9.440968712894252],
    [42.61965419484216, 9.440835813562877],
    [42.61966678254386, 9.440701562493468],
    [42.61968430608665, 9.440573898157986],
    [42.61969955498287, 9.440445692937068],
    [42.61971434381299, 9.440313754824185],
    [42.61972692552619, 9.440180509323513],
    [42.61973735709353, 9.440044827391908],
    [42.61974401165803, 9.439906559959285],
    [42.6197455292818, 9.439770024969809],
    [42.61974536090327, 9.439635989076001],
    [42.61974635924086, 9.439504469338196],
    [42.61974867276327, 9.439377394387234],
    [42.6197523593242, 9.439257231960612],
    [42.61975127810254, 9.439140020328679],
    [42.61974719346357, 9.439027112419323],
    [42.61974177086046, 9.438915234412166],
    [42.61973558119085, 9.438799964743341],
    [42.61973409220661, 9.438681311488729],
    [42.61973440098797, 9.4385589110911],
    [42.6197359924, 9.438434518126922],
    [42.61973816390805, 9.438311858973192],
    [42.61973938414007, 9.438194464504146],
    [42.61974129298785, 9.438083968947589],
    [42.61974305999757, 9.437982907626267],
    [42.61974510054806, 9.437891199511414],
    [42.61975133348911, 9.437810262478372],
    [42.61986723767662, 9.43760828646357],
    [42.61992643812243, 9.437621055892004],
    [42.61992600213771, 9.437629646645233],
    [42.61995532368624, 9.437646926232265],
    [42.62001564289578, 9.437678766601682],
    [42.62007740400893, 9.437716815803523],
    [42.62014078315039, 9.4377531409708],
    [42.62020363931747, 9.437785904980187],
    [42.62026477579784, 9.437808519751917],
    [42.62032173276886, 9.437814359975592],
    [42.62037295530718, 9.437804456912966],
    [42.62041586703626, 9.437778684182319],
    [42.62047640547588, 9.437711827851029],
    [42.62050165429228, 9.437650644308127],
    [42.62052685451041, 9.437602093808543],
    [42.62067648989821, 9.437272576228501],
    [42.62067509947927, 9.437278132674404],
    [42.62062768393457, 9.437176604034391],
    [42.6203796410702, 9.436708083611325],
    [42.62038456578903, 9.436723031381275],
    [42.62034943963832, 9.436687076135026],
    [42.62027998472941, 9.43661371155016],
    [42.6202125507062, 9.436553917523531],
    [42.62014008450532, 9.436493941881638],
    [42.62006291783116, 9.436431360810065],
    [42.61998306878664, 9.436367265092876],
    [42.61990073850032, 9.436302138081128],
    [42.61981851867465, 9.436238116954664],
    [42.61977860046965, 9.43620851584277],
    [42.61969667519323, 9.43614546825293],
    [42.61961290447086, 9.436068427467832],
    [42.61953547011636, 9.435997370010789],
    [42.61946544430323, 9.43593308388103],
    [42.61940566434512, 9.435876530755936],
    [42.61935467805864, 9.43582844411227],
    [42.61929854587286, 9.435784074598075],
    [42.61924982191558, 9.435746427324553],
    [42.61921685612989, 9.4357406097919],
    [42.61898668049252, 9.435706777227368],
    [42.61899052606532, 9.435697698950364],
    [42.61897734824777, 9.435684938587082],
    [42.61896519939486, 9.435672652371483],
    [42.61894053135168, 9.435650820932008],
    [42.61890702993985, 9.43560674906697],
];

const pathOptions = { color: '#2B6FB4', weight: 8 };

function Carte({ dimensions }) {
    const [markerLat, setMarkerLat] = useState(0);
    const [markerLong, setMarkerLong] = useState(0);
    const [mapRef, setMapRef] = useState();
    const [arrets, setArrets] = useState();
    const [navetteOffline, setNavetteOffline] = useState(true);

    //Redimensionne carte si la taile de l'écran change
    useEffect(() => {
        if (mapRef) {
            const timeout = setTimeout(() => {
                console.log('map resize');
                mapRef.invalidateSize();
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [dimensions]);

    const watch = true;
    const { latitude, longitude, speed, timestamp, accuracy, error } = usePosition(watch, { enableHighAccuracy: true });

    useEffect(() => {
        getArrets();
    }, []);

    useEffect(() => {
        getBusPosition();

        if (!navetteOffline) {
            const interval = setInterval(() => {
                getBusPosition();
            }, 5000);
            return () => clearInterval(interval);
        }

        if (navetteOffline) {
            const interval2 = setInterval(() => {
                getBusPosition();
            }, 60000);
            return () => clearInterval(interval2);
        }
    }, [navetteOffline]);

    const getBusPosition = () => {
        fetch(
            `https://navette-biguglia.lagenza.fr/application_navette/webservice/localisation.php?token=${REACT_APP_TOKEN}&bus_code=${REACT_APP_BUS_CODE}`
        )
            .then((res) => res.json())
            .then(
                ({ data, error }) => {
                    if (error === true) {
                        setNavetteOffline(true);
                    } else {
                        setNavetteOffline(false);
                        setMarkerLat(parseFloat(data.latitude));
                        setMarkerLong(parseFloat(data.longitude));
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const getArrets = () => {
        fetch(
            `https://navette-biguglia.lagenza.fr/application_navette/webservice/information.php?line_code=${REACT_APP_LINE_CODE}&bus_code=${REACT_APP_BUS_CODE}`
        )
            .then((res) => res.json())
            .then(
                ({ data }) => {
                    console.log(data);
                    setArrets(data.circuit[0].arret);
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const findUserPosition = () => {
        if (latitude && longitude && !error) {
            mapRef.flyTo([latitude, longitude], 15, { animate: true });
        } else {
            console.log(error);
        }
    };

    const findNavettePosition = () => {
        if (!navetteOffline && markerLat && markerLong) {
            mapRef.flyTo([markerLat, markerLong], 15, { animate: true });
        } else {
            console.log(error);
        }
    };

    return (
        <div>
            <div className='map'>
                {navetteOffline && (
                    <p className='alert'>
                        La navette n'est pas en service pour le moment
                        <br />
                        <button onClick={() => window.location.reload()}>Actualiser</button>
                    </p>
                )}
                <MapContainer whenCreated={(map) => setMapRef(map)} center={[42.6186076, 9.4360093]} zoom={14} zoomControl={false}>
                    <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                    {arrets &&
                        arrets.map(({ name, latitude, longitude, url_image }, index) => (
                            <Marker key={index} position={[latitude, longitude]} icon={arretIcon}>
                                <Popup>
                                    <div className='popup'>
                                        {url_image && <img src={url_image} />}
                                        <p className='text-uppercase text-bold'>{name}</p>
                                    </div>
                                </Popup>
                            </Marker>
                        ))}
                    {!navetteOffline && markerLat && markerLong && <Marker zIndexOffset={1000} position={[markerLat, markerLong]} icon={busIcon} />}
                    {latitude && longitude && !error && <Marker position={[latitude, longitude]} icon={userIcon} />}
                    <Polyline pathOptions={pathOptions} positions={circuit} />
                    <button onClick={findUserPosition} className='button-location'>
                        <UserLocIcon />
                    </button>
                    {!navetteOffline && (
                        <button onClick={findNavettePosition} className='button-location bus'>
                            <BusLocIcon />
                        </button>
                    )}
                    <ZoomControl position='bottomleft' />
                </MapContainer>
            </div>
        </div>
    );
}

export default Carte;
